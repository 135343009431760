import { combineReducers } from "redux";
import layoutState from "./layoutReducer";
import meState from './meReducer';
import rcErrorState from './rcErrorReducer';
import rocketChatState from './rocketChatReducer';


export default combineReducers({
  meState,
  rcErrorState,
  rocketChatState,
  layoutState
})