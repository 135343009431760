import moment from 'moment'
import EJSON from 'ejson'
import _ from 'lodash'
import AppUtils from "@utils/appUtil"

export const normalizeMessage = (message) => {
    if (message) {
        let result = EJSON.fromJSONValue(message)
        return {
            ...result,
            _updatedAt: moment(result._updatedAt).toDate(),
            ts: moment(result.ts).toDate(),
        }
    }
}

export const normalizeSubscription = (subscription) => {
    const parsedSub = EJSON.fromJSONValue(subscription)
    return {
        ...parsedSub,
        _updatedAt: moment(parsedSub._updatedAt).toDate(),
        ls: parsedSub.ls && moment(parsedSub.ls).toDate(),
    }
}

export const normalizeRoom = (room) => {
    const parsedRoom = EJSON.fromJSONValue(room)

    return {
        ...parsedRoom,
        _updatedAt: moment(parsedRoom._updatedAt).toDate(),
        lm: parsedRoom.lm && moment(parsedRoom.lm).toDate(),
        lastMessage: parsedRoom.lastMessage && normalizeMessage(parsedRoom.lastMessage),
    }
}

export const normalizeSubroom = (subroom) => {
    const clonedSubroom = _.clone(subroom)
    const parsedSubroom = normalizeSubscription(clonedSubroom)
    const parsedRoom = parsedSubroom.room && normalizeRoom(parsedSubroom.room)

    return {
        ...parsedSubroom,
        room: parsedRoom
    }
}

export const lastUpdateString = (lastUpdated) => {
    if (!lastUpdated || !moment(lastUpdated).isValid()) {
        return ''
    }

    if (AppUtils.isSameDay(moment().toISOString(), lastUpdated)) {
        return moment(lastUpdated)
            .format('LT')
            .replace('AM', 'am')
            .replace('PM', 'pm')

    }

    return moment(lastUpdated).format('MMM DD')
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    normalizeMessage,
    normalizeRoom,
    normalizeSubscription,
    normalizeSubroom,
    lastUpdateString,
}