import rocketChatClient from '@services/rocketchatApiRequest'
import rcErrorActions from '@stores/actions/rcErrorActions'
import rcSubscriptionActions from '@stores/actions/rcSubscriptionActions'
import rocketChatActions from '@stores/actions/rocketChatActions'
import * as ls from 'local-storage'
import _ from 'lodash'
import moment from 'moment'
import { ofType } from 'redux-observable'
import { RealTimeAPI } from 'rocket.chat.realtime.api.rxjs'
import { Observable } from 'rxjs'
import { map, mergeMap, take, flatMap, debounceTime } from 'rxjs/operators'

const initConnection = (
  action$: any,
  store: any,
  { realtimeAPI }: { realtimeAPI: RealTimeAPI }
) =>
  action$.pipe(
    ofType(rocketChatActions.actionTypes.INIT_CONNECTION),
    mergeMap(() => {
      return realtimeAPI.connectToServer().pipe(
        map(() => {
          return rocketChatActions.connectionEstablished({
            server: _.get(realtimeAPI, 'url'),
          })
        })
      )
    })
  )

const setCurrentLoginInfo = (action$: any, store: any) =>
  action$.pipe(
    ofType(
      rocketChatActions.actionTypes.SET_CURRENT_LOGIN_INFO,
      rocketChatActions.actionTypes.GET_USER_PRESENCE_LIST
    ),
    mergeMap((action: any) => {
      if (
        action.type === rocketChatActions.actionTypes.SET_CURRENT_LOGIN_INFO
      ) {
        const {
          rcCurrentLoginInfo: {
            data: { userId, authToken },
          },
        } = action

        ls.set('rocketchat.userId', userId)
        ls.set('rocketchat.authToken', authToken)
      }

      return Observable.of({ type: 'NO_ACT' })
    }),
    mergeMap(() =>
      Observable.fromPromise(rocketChatClient.get('v1/users.presence'))
    ),
    map(({ users }: { users: any[] }) =>
      rocketChatActions.setUserPresenceList(users)
    )
  )

const loginForRealtimeApi = (
  action$: any,
  store: any,
  { realtimeAPI }: { realtimeAPI: RealTimeAPI }
) =>
  action$.pipe(
    ofType(rocketChatActions.actionTypes.LOGIN_FOR_REALTIME_API),
    mergeMap((action: any) => {
      const { username, password } = action.payload
      return realtimeAPI.login(username, password).pipe(
        flatMap((msg: any) => {
          switch (msg.msg) {
            case 'result':
              if (msg.result)
                return [
                  rocketChatActions.setCurrentLoginInfoForRealtimeApi(
                    msg.result
                  ),
                  rocketChatActions.startStreamNotifyUser(
                    msg.result.id,
                    'notification'
                  ),
                ] as any[]

              return [rcErrorActions.addError(msg)]
            case 'added':
              return [
                rocketChatActions.setCurrentLoginInfoForRealtimeApi(msg.fields),
              ]
            case 'error':
              return [rcErrorActions.addError(msg.error)]
            default:
              return [rcErrorActions.addError(msg)]
          }
        })
      )
    })
  )

const startSteamNotifyLogged = (
  action$: any,
  store: any,
  { realtimeAPI }: { realtimeAPI: RealTimeAPI }
) =>
  action$.pipe(
    ofType(
      rocketChatActions.actionTypes.START_NOTIFY_LOGGED,
      rocketChatActions.actionTypes.SET_CURRENT_LOGIN_INFO_FOR_REALTIME_API
    ),
    take(1),
    mergeMap(() => {
      return realtimeAPI
        .getSubscription('stream-notify-logged', 'user-status', false)
        .takeUntil(
          action$.pipe(
            ofType(rcSubscriptionActions.actionTypes.STOP_STREAM_NOTIFY_USER)
          )
        )
        .map(({ fields: { args } }: any) => {
          return rocketChatActions.getUserPresenceList()
        })
    })
  )

const subscribeToRoom = (
  action$: any,
  store: any,
  { realtimeAPI }: { realtimeAPI: RealTimeAPI }
) =>
  action$.pipe(
    ofType(rocketChatActions.actionTypes.SUBSCRIBE_ROOM),
    mergeMap(({ rid }) => {
      return realtimeAPI
        .getSubscription('stream-room-messages', rid, false)
        .takeUntil(
          action$.pipe(ofType(rocketChatActions.actionTypes.UNSUBSCRIBE_ROOM))
        )
        .flatMap((msg: any) => {
          switch (msg.msg) {
            case 'changed':
              const [message] = _.get(msg, 'fields.args', [])
              const {
                rocketChatState: { roomChatHistory },
              } = store.value
              const messages = _.map(
                _.get(roomChatHistory, message.rid, [])
              ) as any[]

              return [
                rocketChatActions.setRoomChatLastMessage(message.rid, message),
                rocketChatActions.setRoomChatHistory(
                  message.rid,
                  [message].concat(messages)
                ),
                rocketChatActions.scrollDown(),
              ] as any[]
            default:
              return [rcErrorActions.addError(msg.error)]
          }
        })
    })
  )

const scrollDown = (action$: any, store: any) =>
  action$.pipe(
    ofType('@@ROCKETCHAT_SCROLL_DOWN'),
    mergeMap(() => {
      const scrollToBottomFn = _.get(window, 'chatScroll.scrollToBottom')
      if (_.isFunction(scrollToBottomFn)) scrollToBottomFn()

      return Observable.of({ type: 'NO_ACT' })
    })
  )

const getSubscriptions = (
  action$: any,
  store: any,
  { realtimeAPI }: { realtimeAPI: RealTimeAPI }
) =>
  action$.pipe(
    ofType(rocketChatActions.actionTypes.GET_SUBSCRIPTIONS),
    mergeMap(() =>
      realtimeAPI.callMethod('subscriptions/get').flatMap((msg: any) => {
        switch (msg.msg) {
          case 'result':
            const actions: any[] = [
              rocketChatActions.setSubscriptions(msg.result),
            ]
            return actions
          case 'error':
            return [rcErrorActions.addError(msg.error)]
          default:
            return [rcErrorActions.addError(msg.error)]
        }
      })
    )
  )

const createDirectChat = (
  action$: any,
  store: any,
  { realtimeAPI }: { realtimeAPI: RealTimeAPI }
) =>
  action$.pipe(
    ofType(rocketChatActions.actionTypes.CREATE_DIRECT_MESSAGE),
    mergeMap(({ username }) =>
      realtimeAPI
        .callMethod('createDirectMessage', username)
        .map((msg: any) => {
          const rid = msg.result?.rid
          switch (msg.msg) {
            case 'result':
              if (msg.error) return rcErrorActions.addError(msg.error)

              return rocketChatActions.prepareDirectMessage(rid)
            default:
              return rcErrorActions.addError(msg.error)
          }
        })
    )
  )

const loadHistoryForFirstTime = (
  action$: any,
  store: any,
  { realtimeAPI }: { realtimeAPI: RealTimeAPI }
) =>
  action$.pipe(
    ofType(rocketChatActions.actionTypes.SELECT_ROOM_CHAT),
    mergeMap(({ selectedRoomChat }) => {
      const { roomChatHistory } = store.value.rocketChatState
      if (!selectedRoomChat) return Observable.of({ type: 'NO_ACT' })

      if (_.some(roomChatHistory[selectedRoomChat.rid]))
        return Observable.of({ type: 'NO_ACT' })

      const fromTime = null
      const toTime = moment().unix() // now
      return realtimeAPI
        .callMethod(
          'loadHistory',
          selectedRoomChat.rid,
          fromTime!,
          50,
          { $date: toTime },
          false
        )
        .pipe(
          map((msg: any) => {
            switch (msg.msg) {
              case 'result':
                return rocketChatActions.loadHistoryForFirstTime(
                  msg,
                  selectedRoomChat
                )
              default:
                return rcErrorActions.addError(msg.error)
            }
          })
        )
    })
  )

const loadHistory = (
  action$: any,
  store: any,
  { realtimeAPI }: { realtimeAPI: RealTimeAPI }
) =>
  action$.pipe(
    ofType(rocketChatActions.actionTypes.LOAD_HISTORY),
    mergeMap(({ to, cb }) => {
      const { selectedRoomChat } = store.value.rocketChatState
      if (!selectedRoomChat) return Observable.of({ type: 'NO_ACT' })

      return realtimeAPI
        .callMethod(
          'loadHistory',
          selectedRoomChat.rid,
          to,
          50,
          { $date: moment().unix() },
          false
        )
        .pipe(
          map((msg: any) => {
            switch (msg.msg) {
              case 'result':
                const olderHistories = _.get(msg, 'result.messages', [])
                return rocketChatActions.prependRoomChatHistory(
                  selectedRoomChat.rid,
                  olderHistories,
                  cb
                )
              case 'error':
                return rcErrorActions.addError(msg.error)
              default:
                return rcErrorActions.addError(msg.error)
            }
          })
        )
    })
  )

const sendMessage = (
  action$: any,
  store: any,
  { realtimeAPI }: { realtimeAPI: RealTimeAPI }
) =>
  action$.pipe(
    ofType(rocketChatActions.actionTypes.SEND_MESSAGE),
    mergeMap(({ message }) => {
      return realtimeAPI.callMethod('sendMessage', message).pipe(
        map((msg: any) => {
          switch (msg.msg) {
            case 'result':
              // console.log('msg.result :>> ', msg.result)
              return rocketChatActions.getLatestMessage(msg.result)
            case 'error':
              return rcErrorActions.addError(msg.error)
            default:
              return rcErrorActions.addError(msg.error)
          }
        })
      )
    })
  )

const startStreamNotifyUser = (
  action$: any,
  store: any,
  { realtimeAPI }: { realtimeAPI: RealTimeAPI }
) =>
  action$.pipe(
    ofType(rocketChatActions.actionTypes.START_STREAM_NOTIFY_USER),
    debounceTime(250),
    mergeMap(({ event, userId }) => {
      return realtimeAPI
        .getSubscription('stream-notify-user', `${userId}/${event}`, false)
        .takeUntil(
          action$.pipe(
            ofType(rocketChatActions.actionTypes.STOP_STREAM_NOTIFY_USER)
          )
        )
        .map((msg: any) => {
          switch (msg.msg) {
            case 'changed':
              const [arg] = _.get(msg, 'fields.args', [])

              if (
                _.has(store, ['rocketChatState.subscriptions', arg.payload.rid])
              )
                return rocketChatActions.increaseRoomUnread(
                  arg.payload.rid,
                  arg.payload
                )

              return rocketChatActions.prepareNewSubscription(
                arg.payload.rid,
                arg.payload.username
              )
            default:
              return rcErrorActions.addError(msg.error)
          }
        })
    })
  )

const readMessages = (
  action$: any,
  store: any,
  { realtimeAPI }: { realtimeAPI: RealTimeAPI }
) =>
  action$.pipe(
    ofType(rocketChatActions.actionTypes.READ_MESSAGES),
    debounceTime(250),
    mergeMap(({ rid }) => {
      return realtimeAPI.callMethod('readMessages', rid).pipe(
        map((msg) => {
          return { type: 'NO_ACT' }
        })
      )
    })
  )

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  subscribeToRoom,
  getSubscriptions,
  initConnection,
  setCurrentLoginInfo,
  loginForRealtimeApi,
  startSteamNotifyLogged,
  createDirectChat,
  loadHistoryForFirstTime,
  loadHistory,
  sendMessage,
  startStreamNotifyUser,
  scrollDown,
  readMessages,
}
