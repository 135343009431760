import { Snackbar } from '@material-ui/core'
import React, { useState } from 'react'
import { FC } from 'react'
import MuiAlert from '@material-ui/lab/Alert';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useEffect } from 'react';

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ErrorSnackbar: FC = () => {
  const rcErrorState = useSelector(state => _.get(state, "rcErrorState"))
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(_.some(rcErrorState))
  }, [rcErrorState])

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <Snackbar open={isOpen} onClose={handleClose}>
      <Alert onClose={handleClose} severity="error">
        <span>{_.get(_.last(rcErrorState), "reason", "Something went wrong")}</span>
      </Alert>
    </Snackbar>
  )
}

export default ErrorSnackbar
