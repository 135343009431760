import UserChatSkeleton from '@components/Skeleton/UserChatSkeleton'
import {
  Card,
  CircularProgress,
  Paper,
  Menu,
  MenuItem,
  Button,
  Typography,
} from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import rocketChatServices from '@services/rocketChatServices'
import rocketChatActions from '@stores/actions/rocketChatActions'
import classNames from 'classnames'
import _ from 'lodash'
import moment from 'moment'
import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AutoSizer, List } from 'react-virtualized'
import { RenderedRows } from 'react-virtualized/dist/es/List'
import ChatContact from '../ChatContact'
import ChatListItem from '../ChatListItem'
import ChatMenu from '../ChatMenu'
import SearchControl from '../SearchControl'
import 'react-loading-skeleton/dist/skeleton.css'

interface ChatListProps {
  openSearch?: boolean
  toggleSearch?: any
}

const SortTypes = {
  Recent: 'Recent activities',
  Unread: 'Unread messages',
}

function TabPanel(props) {
  const { children, value, id, show, ...other } = props

  return (
    <div
      className={classNames({
        'flex-fill chat-list flex-column d-flex justify-content-center align-items-center w-100 column-chat':
          show,
        'd-none': !show,
      })}
      role="tabpanel"
      id={`scrollable-prevent-tabpanel-${id}`}
      aria-labelledby={`scrollable-prevent-tab-${id}`}
      {...other}
    >
      <div className="flex-fill chat-list d-flex flex-column justify-content-start align-items-center w-100">
        {props.children}
      </div>
    </div>
  )
}

const ChatList: FC<ChatListProps> = (props) => {
  const { openSearch, toggleSearch } = props

  const dispatch = useDispatch()
  const {
    meState: { profile },
    rocketChatState: {
      userPresenceList,
      subscriptions,
      selectedRoomChat,
      rsusers,
      chatListloading,
      chatListFocusIndex,
    },
    layoutState: { activeTabId },
  } = useSelector((state: any) => state)

  const [subscriptionName, setSubscriptionName] = useState('')
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleSortClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const [sortType, setSortType] = useState(SortTypes.Recent)

  const createDirectChat = (chatItem: any) => {
    dispatch(rocketChatActions.createDirectMessage(chatItem.name))
  }

  const getSortedSubscriptions = (
    subscriptions: any,
    subscriptionName?: string
  ) => {
    let sortedSubs: any[]
    if (sortType === SortTypes.Unread) {
      sortedSubs = _.orderBy(
        subscriptions,
        [
          'unread',
          (sub) => {
            if (sub.room?.lm) {
              return moment(sub.room?.lm).valueOf()
            }
            return false
          },
        ],
        ['desc', 'desc']
      )
    } else {
      sortedSubs = _.orderBy(
        subscriptions,
        (sub) => {
          if (sub.room?.lm) {
            return moment(sub.room?.lm).valueOf()
          }
          return false
        },
        'desc'
      )
    }
    return sortedSubs.filter((sub: any) => {
      if (_.isEmpty(subscriptionName)) {
        return true
      }
      return _.includes(_.toLower(sub.fname), _.toLower(subscriptionName))
    })
  }

  const fetchRsUsers = ({ startIndex, stopIndex }: RenderedRows) => {
    const newList = _.unionBy(
      getSortedSubscriptions(subscriptions, subscriptionName),
      'name'
    ).filter((item: any) => {
      return item.rid !== 'GENERAL' && item.name !== profile.rocketchat_username
    })

    const list = newList.filter((item) => {
      return item.rid !== 'GENERAL'
    })

    let rocketchat_usernames: string[] = []

    for (let index = startIndex; index <= stopIndex; index++) {
      rocketchat_usernames.push(_.get(list[index], ['name']))
    }

    if (selectedRoomChat) {
      rocketchat_usernames.push(selectedRoomChat.name)
    }

    rocketChatServices
      .fetchRosterUsers(
        _.filter(
          rocketchat_usernames,
          (name) => !_.keys(rsusers).includes(name)
        )
      )
      .then((rsusers) => dispatch(rocketChatActions.setRsUsers(rsusers)))
  }

  const list = _.unionBy(
    getSortedSubscriptions(subscriptions, subscriptionName),
    'name'
  ).filter((item: any) => {
    return item.rid !== 'GENERAL' && item.name !== profile.rocketchat_username
  })

  const showListItem = () => {
    const result = list.filter((word) => word.unread > 0)
    return result
  }
  showListItem()

  const renderSkeleton = () => {
    return new Array(20).fill(null).map(() => <UserChatSkeleton />)
  }

  return (
    <React.Fragment>
      <div
        className="selection-chat d-flex flex-column align-items-start"
        style={{
          minWidth: 360,
          maxWidth: 360,
          height: '100vh',
          paddingTop: 24,
          paddingRight: 32,
          scrollPaddingRight: 24,
        }}
      >
        <h2 className="title-chat">Chat</h2>
        <SearchControl
          onSearch={setSubscriptionName}
          placeHolder="Search name friend"
        />
        {_.isEmpty(rsusers) ? (
          <div
            className="icon-empty"
            style={{ marginTop: 20, marginLeft: 'auto', marginRight: 'auto' }}
          >
            <img src="/images/empty_contact.svg" alt="icon empty" />
            <p style={{ color: '#9d9d9d' }}>Empty chat</p>
          </div>
        ) : (
          <React.Fragment key={0}>
            <TabPanel id={0} show={activeTabId === 0}>
              <Card className="p-2 w-100 mb-2 sort-recent">
                <div className="w-100 d-flex justify-content-between align-items-center">
                  <div className="icon-recent">
                    <img src="/images/search-recent.svg" alt="icon-recent" />
                  </div>
                  <Button
                    aria-haspopup="true"
                    style={{ textTransform: 'capitalize' }}
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleSortClick}
                    endIcon={<ArrowDropDownIcon />}
                    className="menu-sort-chat"
                  >
                    {sortType}
                  </Button>
                  <Menu
                    className="menu-dropdown"
                    id="demo-positioned-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    {Object.keys(SortTypes).map((key) => (
                      <MenuItem
                        className=""
                        onClick={() => {
                          setSortType(SortTypes[key])
                          handleClose()
                        }}
                      >
                        {SortTypes[key]}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </Card>

              {chatListloading && (
                <div className="d-flex flex-column justify-content-center align-items-center flex-fill">
                  <CircularProgress />
                </div>
              )}
              {!chatListloading && _.isEmpty(rsusers) ? (
                renderSkeleton()
              ) : (
                <AutoSizer className="w-100 d-flex flex-column justify-content-center align-items-center flex-fill">
                  {({ height, width }) => (
                    <List
                      onRowsRendered={_.debounce(fetchRsUsers, 500)}
                      scrollToIndex={chatListFocusIndex}
                      height={height}
                      rowCount={list.length}
                      rowHeight={67}
                      width={width}
                      rowRenderer={({ key, index, style }) => {
                        const item = list[index]
                        return (
                          <div
                            key={key}
                            style={style}
                            className="box-item-chat"
                          >
                            <ChatListItem
                              urlProfile={true}
                              item={{
                                name: _.get(rsusers, [item.name, 'name']),
                                lastMessage: item.room?.lastMessage?.msg,
                                unreadCount: item.unread,
                                avatar: _.get(
                                  rsusers,
                                  [item.name, 'profile_image_url'],
                                  process.env.REACT_APP_DEFAULT_AVATAR
                                ),
                                target: _.get(rsusers, [
                                  item.name,
                                  'target_group_id',
                                ]),
                                profileId: _.get(rsusers, [
                                  item.name,
                                  'profile_ref_id',
                                ]),
                              }}
                              selected={
                                _.get(selectedRoomChat, 'rid') === item?.rid
                              }
                              onClick={() => {
                                createDirectChat(item)
                                toggleSearch(false)
                              }}
                              online={_.some(
                                userPresenceList,
                                (onl) => onl.username === item.name
                              )}
                              timestamp={item.room?.lm}
                              loading={Boolean(
                                !_.get(rsusers, [item.name, 'name'])
                              )}
                            />
                          </div>
                        )
                      }}
                    />
                  )}
                </AutoSizer>
              )}
            </TabPanel>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  )
}

export default ChatList
