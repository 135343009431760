import { Snackbar } from '@material-ui/core'
import React, { FC } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'

const vertical = "top"
const horizontal = "center"

const SnackbarMessage: FC = () => {
  const [open, setOpen] = useState(false)

  useEffect(() => {


  }, [])

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={() => setOpen(false)}
      message="I love snacks"
      key={vertical + horizontal}
    />
  )
}

export default SnackbarMessage
