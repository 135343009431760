import rocketChatActions from '@stores/actions/rocketChatActions'
import _ from 'lodash'
import { v4 } from 'uuid'

const initState = {
  currentLoginInfoForRealtimeApi: null,
  rcCurrentLoginInfo: {
    data: {
      me: {},
    },
  },
  isConnected: false,
  server: null,
  userPresenceList: [],
  subscriptions: {},
  selectedRoomChat: null,
  roomChatHistory: {},
  selectedRoomChatLoading: false,
  retry: '',
  rsusers: {},
  mesasgeIdFocused: '',
  stopFetchMessage: false,
  chatListloading: true,
  chatListFocusIndex: 0,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initState, action: any) {
  switch (action.type) {
    case rocketChatActions.actionTypes.SET_CURRENT_LOGIN_INFO:
      return {
        ...state,
        rcCurrentLoginInfo: action.rcCurrentLoginInfo,
      }
    case rocketChatActions.actionTypes.CONNECTION_ESTABLISHED:
      return {
        ...state,
        isConnected: true,
        server: action.connection?.server,
      }
    case rocketChatActions.actionTypes.SET_CURRENT_LOGIN_INFO_FOR_REALTIME_API:
      return {
        ...state,
        currentLoginInfoForRealtimeApi: {
          ...(state.currentLoginInfoForRealtimeApi ?? {}),
          ...action.currentLoginInfoForRealtimeApi,
        },
      }
    case rocketChatActions.actionTypes.SET_USER_PRESENCE_LIST:
      return {
        ...state,
        userPresenceList: action.userPresenceList,
      }
    case rocketChatActions.actionTypes.PREPARE_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action.subscriptions,
      }
    case rocketChatActions.actionTypes.SELECT_ROOM_CHAT:
      return {
        ...state,
        selectedRoomChat: action.selectedRoomChat,
      }
    case rocketChatActions.actionTypes.SET_ROOM_CHAT_HISTORY:
      return {
        ...state,
        roomChatHistory: {
          [action.rid]: action.histories,
        },
      }
    case rocketChatActions.actionTypes.PREPEND_HISTORY: {
      const currentHistories = _.map(
        _.get(state.roomChatHistory, action.rid, [])
      )
      return {
        ...state,
        stopFetchMessage: !_.some(action.histories),
        roomChatHistory: {
          [action.rid]: _.unionBy(currentHistories, action.histories, '_id'),
        },
      }
    }
    case rocketChatActions.actionTypes.CLEAR_ROOM_CHAT_HISTORIES:
      return {
        ...state,
        roomChatHistory: {},
      }
    case rocketChatActions.actionTypes.SET_ROOM_CHAT_LOADING:
      return {
        ...state,
        selectedRoomChatLoading: action.selectedRoomChatLoading,
      }
    case rocketChatActions.actionTypes.UPDATE_ONE_SUBSCRIPTION:
      return {
        ...state,
        subscription: {
          ...state.subscriptions,
          [action.subscription.rid]: action.subscription,
        },
      }

    case rocketChatActions.actionTypes.SET_ONE_SUBSCRIPTION:
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          [action.rid]: {
            ...action.subscription,
          },
        },
      }
    case rocketChatActions.actionTypes.READ_MESSAGES:
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          [action.rid]: {
            ...state.subscriptions[action.rid],
            unread: 0,
          },
        },
      }
    case rocketChatActions.actionTypes.RETRY:
      return {
        ...state,
        retry: v4(),
      }
    case rocketChatActions.actionTypes.SET_RSUSERS:
      return {
        ...state,
        rsusers: {
          ...state.rsusers,
          ...action.rsusers,
        },
      }
    case rocketChatActions.actionTypes.SET_MESSAGE_ID_FOCUSED:
      return {
        ...state,
        mesasgeIdFocused: action.mesasgeIdFocused,
      }
    case rocketChatActions.actionTypes.SET_CHAT_LIST_LOADING:
      return {
        ...state,
        chatListloading: action.chatListloading,
      }
    case rocketChatActions.actionTypes.GET_LATEST_MESSAGES:
      const selectedRoom = [
        action.message,
        ...state.roomChatHistory[action.message.rid],
      ]
      const newHistoryChat = {
        ...state.roomChatHistory,
        [action.message.rid]: selectedRoom,
      }

      // console.log('newHistoryChat :>> ', newHistoryChat)
      return {
        ...state,
        roomChatHistory: newHistoryChat,
      }

    default:
      return state
  }
}
