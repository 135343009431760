import _ from 'lodash'
import { v4 } from 'uuid'

const actionTypes = {
  SET_CURRENT_LOGIN_INFO: '@@ROCKETCHAT_SET_CURRENT_LOGIN_INFO',
  SET_USER_PRESENCE_LIST: '@@ROCKETCHAT_SET_USER_PRESEMCE_LIST',
  INIT_CONNECTION: '@@ROCKETCHAT_INIT_CONNECTION',
  CONNECTION_ESTABLISHED: '@@ROCKETCHAT_CONNECTION_ESTABLISHED',
  GET_USER_PRESENCE_LIST: '@@ROCKETCHAT_GET_USER_PRESEMCE_LIST',
  LOGIN_FOR_REALTIME_API: '@@ROCKETCHAT_LOGIN_FOR_REALTIME_API',
  SET_CURRENT_LOGIN_INFO_FOR_REALTIME_API:
    '@@ROCKETCHAT_SET_CURRENT_LOGIN_INFO_FOR_REALTIME_API',
  START_NOTIFY_LOGGED: '@@ROCKETCHAT_START_NOTIFY_LOGGED',
  LOAD_HISTORY: '@@ROCKETCHAT_LOAD_HISTORY',
  PREPEND_HISTORY: '@@ROCKETCHAT_PREPEND_HISTORY',
  SUBSCRIBE_ROOM: '@@ROCKETCHAT_SUBSCRIBE_ROOM',
  SCROLL_DOWN: '@@ROCKETCHAT_SCROLL_DOWN',
  UNSUBSCRIBE_ROOM: '@@ROCKETCHAT_UNSUBSCRIBE_ROOM',
  GET_SUBSCRIPTIONS: '@@ROCKETCHAT_GET_SUBSCRIPTIONS',
  SET_SUBSCRIPTIONS: '@@ROCKETCHAT_SET_SUBSCRIPTIONS',
  PREPARE_SUBSCRIPTIONS: '@@ROCKETCHAT_PREPARE_SUBSCRIPTIONS',
  CREATE_DIRECT_MESSAGE: '@@ROCKETCHAT_CREATE_DIRECT_MESSAGE',
  SELECT_ROOM_CHAT: '@@ROCKETCHAT_SELECT_ROOM_CHAT',
  SET_ROOM_CHAT_HISTORY: '@@ROCKETCHAT_SET_ROOM_CHAT_HISTORY',
  SEND_MESSAGE: '@@ROCKETCHAT_SEND_MESSAGE',
  START_STREAM_NOTIFY_USER: '@@ROCKETCHAT_START_STREAM_NOTIFY_USER',
  STOP_STREAM_NOTIFY_USER: '@@ROCKETCHAT_STOP_STREAM_NOTIFY_USER',
  START_STREAM_NOTIFY_LOGED: '@@ROCKETCHAT_START_STREAM_NOTIFY_LOGED',
  STOP_LOGED_STREAM_NOTIFY_LOGED: '@@ROCKETCHAT_LOGED_STREAM_NOTIFY_LOGED',
  SET_ROOM_CHAT_LOADING: '@@ROCKETCHAT_SET_ROOM_CHAT_LOADING',
  CLEAR_ROOM_CHAT_HISTORIES: '@@ROCKETCHAT_CLEAR_ROOM_CHAT_HISTORIES',
  PREPARE_DIRECT_MESSAGE: '@@ROCKETCHAT_PREPARE_DIRECT_MESSAGE',
  SET_ROOM_CHAT_LAST_MESSAGE: '@@ROCKETCHAT_SET_ROOM_CHAT_LAST_MESSAGE',
  UPDATE_ONE_SUBSCRIPTION: '@@ROCKETCHAT_UPDATE_ONE_SUBSCRIPTION',
  INCREASE_ROOM_UNREAD: '@@ROCKETCHAT_INCREASE_ROOM_UNREAD',
  LOAD_HISTORY_FOR_FIRST_TIME: '@@ROCKETCHAT_LOAD_HISTORY_FOR_FIRST_TIME',
  SET_ONE_SUBSCRIPTION: '@@ROCKETCHAT_SET_ONE_SUBSCRIPTION',
  RETRY: '@@ROCKETCHAT_RETRY',
  SET_RSUSERS: '@@ROCKETCHAT_SET_RSUSERS',
  SET_ONE_RSUSER: '@@ROCKETCHAT_SET_ONE_RSUSER',
  SET_MESSAGE_ID_FOCUSED: '@@ROCKETCHAT_SET_MESSAGE_ID_FOCUSED',
  SET_CHAT_LIST_LOADING: '@@ROCKETCHAT_SET_CHAT_LIST_LOADING',
  SET_CHAT_LIST_INDEX_FOCUS: '@@ROCKETCHAT_SET_CHAT_LIST_INDEX_FOCUS',
  PREPARE_NEW_SUBSCRIPTION: '@@ROCKETCHAT_PREPARE_NEW_SUBSCRIPTION',
  READ_MESSAGES: '@@ROCKETCHAT_READ_MESSAGES',
  GET_LATEST_MESSAGES: '@@ROCKETCHAT_GET_LATEST_MESSAGE',
}

export interface MessageBody {
  _id: string
  rid: string
  msg: string
}

const clearRoomChatHistories = () => ({
  type: actionTypes.CLEAR_ROOM_CHAT_HISTORIES,
})

const setRoomChatLoading = (selectedRoomChatLoading: Boolean) => ({
  type: actionTypes.SET_ROOM_CHAT_LOADING,
  selectedRoomChatLoading,
})

const scrollDown = () => ({
  type: actionTypes.SCROLL_DOWN,
})

const setCurrentLoginInfo = (rcCurrentLoginInfo: any) => ({
  type: actionTypes.SET_CURRENT_LOGIN_INFO,
  rcCurrentLoginInfo,
})

const setUserPresenceList = (userPresenceList: any[]) => ({
  type: actionTypes.SET_USER_PRESENCE_LIST,
  userPresenceList,
})

const getUserPresenceList = () => ({
  type: actionTypes.GET_USER_PRESENCE_LIST,
})

const initConnection = () => ({
  type: actionTypes.INIT_CONNECTION,
})

const connectionEstablished = (connection: any) => ({
  type: actionTypes.CONNECTION_ESTABLISHED,
  connection,
})

const loginForRealtimeApi = (payload: any) => ({
  type: actionTypes.LOGIN_FOR_REALTIME_API,
  payload,
})

const setCurrentLoginInfoForRealtimeApi = (
  currentLoginInfoForRealtimeApi: any
) => ({
  type: actionTypes.SET_CURRENT_LOGIN_INFO_FOR_REALTIME_API,
  currentLoginInfoForRealtimeApi,
})

const startNotifyLogged = () => ({
  type: actionTypes.START_NOTIFY_LOGGED,
})

const subscribeToRoom = (rid: string) => ({
  type: actionTypes.SUBSCRIBE_ROOM,
  rid,
})

const unsubscribeToRoom = () => ({
  type: actionTypes.UNSUBSCRIBE_ROOM,
})

const getSubscriptions = (payload?: any) => ({
  type: actionTypes.GET_SUBSCRIPTIONS,
  payload,
})

const setSubscriptions = (subscriptions: any) => ({
  type: actionTypes.SET_SUBSCRIPTIONS,
  subscriptions: _.keyBy(
    _.map(subscriptions, (item: any) => {
      item.key = v4()
      return item
    }),
    'rid'
  ),
})

const setOneSubscription = (rid: string, subscription: any) => ({
  type: actionTypes.SET_ONE_SUBSCRIPTION,
  rid,
  subscription,
})

const prepareSubscriptions = (subscriptions: any) => ({
  type: actionTypes.PREPARE_SUBSCRIPTIONS,
  subscriptions,
})

const createDirectMessage = (username: string) => ({
  type: actionTypes.CREATE_DIRECT_MESSAGE,
  username,
})

const selectRoomChat = (selectedRoomChat: any) => ({
  type: actionTypes.SELECT_ROOM_CHAT,
  selectedRoomChat,
})

const setRoomChatHistory = (rid: string, histories: any[]) => ({
  type: actionTypes.SET_ROOM_CHAT_HISTORY,
  rid,
  histories: histories,
})

const sendMessage = (message: MessageBody) => ({
  type: actionTypes.SEND_MESSAGE,
  message,
})

const startStreamNotifyUser = (userId: string, event: string) => ({
  type: actionTypes.START_STREAM_NOTIFY_USER,
  event,
  userId,
})

const startStreamNotifyLoged = () => ({
  type: actionTypes.START_STREAM_NOTIFY_LOGED,
})

const stopStreamNotifyLoged = () => ({
  type: actionTypes.STOP_STREAM_NOTIFY_USER,
})

const loadHistory = (from: any, to: any, cb?: () => void) => ({
  type: actionTypes.LOAD_HISTORY,
  from,
  to,
  cb,
})

const prependRoomChatHistory = (
  rid: string,
  histories: any[],
  cb?: () => void
) => ({
  type: actionTypes.PREPEND_HISTORY,
  rid,
  histories,
  cb,
})

const prepareDirectMessage = (rid: string) => ({
  type: actionTypes.PREPARE_DIRECT_MESSAGE,
  rid,
})

const setRoomChatLastMessage = (rid: string, message: any) => ({
  type: actionTypes.SET_ROOM_CHAT_LAST_MESSAGE,
  rid,
  message,
})

const updateOneSubscription = (subscription: any) => ({
  type: actionTypes.UPDATE_ONE_SUBSCRIPTION,
  subscription,
})

const increaseRoomUnread = (rid: string, payload: any) => ({
  type: actionTypes.INCREASE_ROOM_UNREAD,
  rid,
  payload,
})

const loadHistoryForFirstTime = (
  loadHistoryResult: any,
  selectedRoomChat: any
) => ({
  type: actionTypes.LOAD_HISTORY_FOR_FIRST_TIME,
  loadHistoryResult,
  selectedRoomChat,
})

const retry = () => ({
  type: actionTypes.RETRY,
})

const setRsUsers = (rsusers: any) => ({
  type: actionTypes.SET_RSUSERS,
  rsusers,
})

const setOneRsUser = (rocketchat_username: string, rsuser: any) => ({
  type: actionTypes.SET_ONE_RSUSER,
  rsuser,
  rocketchat_username,
})

const readMessages = (rid: string) => ({
  type: actionTypes.READ_MESSAGES,
  rid,
})

const setMessageIdFocused = (mesasgeIdFocused: string, cb?: () => void) => ({
  type: actionTypes.SET_MESSAGE_ID_FOCUSED,
  mesasgeIdFocused,
  cb,
})

const setChatListLoading = (chatListloading: boolean) => ({
  type: actionTypes.SET_CHAT_LIST_LOADING,
  chatListloading,
})

const setChatListIndexFocus = (chatListFocusIndex: number) => ({
  type: actionTypes.SET_CHAT_LIST_INDEX_FOCUS,
  chatListFocusIndex,
})

const prepareNewSubscription = (rid: string, username: string) => ({
  type: actionTypes.PREPARE_NEW_SUBSCRIPTION,
  rid,
  username,
})

const getLatestMessage = (message) => ({
  type: actionTypes.GET_LATEST_MESSAGES,
  message,
})

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  prepareNewSubscription,
  actionTypes,
  setChatListIndexFocus,
  setChatListLoading,
  setMessageIdFocused,
  setRsUsers,
  setOneRsUser,
  retry,
  setOneSubscription,
  increaseRoomUnread,
  prepareDirectMessage,
  createDirectMessage,
  setCurrentLoginInfo,
  setUserPresenceList,
  initConnection,
  connectionEstablished,
  getUserPresenceList,
  loginForRealtimeApi,
  setCurrentLoginInfoForRealtimeApi,
  startNotifyLogged,
  subscribeToRoom,
  unsubscribeToRoom,
  getSubscriptions,
  setSubscriptions,
  selectRoomChat,
  setRoomChatHistory,
  sendMessage,
  startStreamNotifyUser,
  startStreamNotifyLoged,
  stopStreamNotifyLoged,
  loadHistory,
  prependRoomChatHistory,
  scrollDown,
  setRoomChatLoading,
  clearRoomChatHistories,
  prepareSubscriptions,
  setRoomChatLastMessage,
  updateOneSubscription,
  loadHistoryForFirstTime,
  readMessages,
  getLatestMessage,
}
