import sessionServices from '@services/sessionServices';
import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import queryString from 'query-string'

const ProtectedRoute = ({ path, component: Component, permission, render, ...rest }: any) => {
  const { url } = queryString.parseUrl(window.location.pathname)
  return (
    <Route
      {...rest}
      render={props => {
        if (!sessionServices.isLoged())
          return (
            <Redirect
              to={{
                pathname: '/login',
                search: url !== '/' ? `?returnUrl=${url}` : '',
                state: { from: props.location },
              }}
            />
          );

        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default ProtectedRoute;
