import { Card, IconButton, TextField } from '@material-ui/core'
import { Close, Search } from '@material-ui/icons'
import React, { FC } from 'react'
import { useForm } from 'react-hook-form'
import { styled } from '@mui/material/styles'

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'transparent',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'transparent',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
})

interface SearchControlProps {
  onSearch?: (textSearch: string) => void
  value?: string
  placeHolder?: string
}

const SearchControl: FC<SearchControlProps> = ({
  onSearch,
  value,
  placeHolder,
}) => {
  const { handleSubmit, register, reset, watch } = useForm({
    defaultValues: {
      textSearch: '',
    },
  })

  const handleSearch = ({ textSearch }) => {
    onSearch?.(textSearch)
  }

  const handleReset = () => {
    reset()
    onSearch?.('')
  }

  return (
    <form onSubmit={handleSubmit(handleSearch)} className="w-100 input-search">
      <CssTextField
        autoFocus
        id="outlined-basic"
        variant="outlined"
        inputProps={register('textSearch')}
        placeholder="Type in the keyword."
        margin="none"
        className="m-0 chat-search-input"
        InputProps={{
          className: 'd-flex justify-content-center align-items-center m-0',
          startAdornment: <img src="/images/search.svg" />,
          endAdornment: watch('textSearch') && (
            <IconButton className="p-0" onClick={handleReset}>
              <Close style={{ color: '#ffffff' }} />
            </IconButton>
          ),
        }}
        fullWidth
        size="small"
      />
    </form>
  )
}

export default SearchControl
