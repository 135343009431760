/* eslint-disable no-restricted-globals */
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import UserSkeleton from '@components/Skeleton/UserSkeleton'
import { yupResolver } from '@hookform/resolvers/yup'
import helpers from '@libs/helpers'
import {
  Avatar,
  Card,
  CircularProgress,
  IconButton,
  Paper,
  TextField,
  Modal,
} from '@material-ui/core'
import Box from '@mui/material/Box'
import DownloadIcon from '@mui/icons-material/Download'
import Tooltip from '@mui/material/Tooltip'
import { Close } from '@material-ui/icons'
import rocketChatServices from '@services/rocketChatServices'
import rocketChatActions from '@stores/actions/rocketChatActions'
import classNames from 'classnames'
import $ from 'jquery'
import _ from 'lodash'
import moment from 'moment'
import React, { FC, useEffect, useRef, useState } from 'react'
import { MessageList as ReactChatElements } from 'react-chat-elements'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useAsyncFn, useList, useToggle } from 'react-use'
import urlcat from 'urlcat'
import { v4 } from 'uuid'
import * as yup from 'yup'
import ChatListItem from '../ChatListItem'
import DeleteMessageDialog from '../DeleteMessageDialog'
import RocketChatUpload from '../RocketChatUpload'
import SearchControl from '../SearchControl'
import { styled } from '@mui/material/styles'

interface MessageListProps {
  open?: boolean
  toggle?: any
}

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'transparent',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'transparent',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
})

const otherFiles = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]

const Person: FC<any> = ({ selectedChatUser, status }) => {
  const { rsusers } = useSelector((state: any) => state.rocketChatState)

  const [onHoverName, setOnHoverName] = useState(false)
  const [onHoverAvatar, setOnHoverAvatar] = useState(false)

  const handleMouseOverName = () => {
    setOnHoverName(true)
  }
  const handleMouseOutName = () => {
    setOnHoverName(false)
  }
  const handleMouseOverAvatar = () => {
    setOnHoverAvatar(true)
  }
  const handleMouseOutAvatar = () => {
    setOnHoverAvatar(false)
  }
  const selectedUsername =
    (_.get(rsusers, [selectedChatUser?.name, 'name']), selectedChatUser?.name)

  const selectedUserSchool =
    (_.get(rsusers, [selectedChatUser?.school_name, 'school_name']),
    selectedChatUser?.shool_name)

  const selectedUser = {
    target_group_id: rsusers[selectedUsername]?.target_group_id,
    profile_id: rsusers[selectedUsername]?.profile_ref_id,
    name: selectedUsername,
    school: selectedUserSchool,
  }
  const urlSelectedProfile = `${process.env.REACT_APP_ROSTERSPOT_APP_URL}/profile/${selectedUser.target_group_id}/${selectedUser.profile_id}/${selectedUser.name}/timeline`

  return (
    <a
      href={urlSelectedProfile}
      style={{ textDecoration: 'none' }}
      target="_blank"
    >
      <div className="d-flex msg-block-item flex-fill">
        <div className="positon-avatar position-relative chat-list">
          <Avatar
            className={classNames(
              'mx-2 border-avatart-chat header-user-avatar',
              {
                'image-hover': onHoverAvatar,
                'text-success': status === 'Online',
              }
            )}
            alt={selectedChatUser?.fname}
            style={{ width: 50, height: 50 }}
            src={
              _.get(
                rsusers,
                [selectedChatUser?.name, 'profile_image_url'],
                process.env.REACT_APP_DEFAULT_AVATAR
              ) === 'undefined'
                ? `${process.env.REACT_APP_ROSTERSPOT_API_BASE_URL}/static/img/user-male-circle-filled.png`
                : _.get(
                    rsusers,
                    [selectedChatUser?.name, 'profile_image_url'],
                    process.env.REACT_APP_DEFAULT_AVATAR
                  )
            }
            onMouseOver={handleMouseOverAvatar}
            onMouseOut={handleMouseOutAvatar}
          />
          {status === 'Online' ? (
            <div className="rounded-circle position-absolute chat-online head-messaging"></div>
          ) : (
            <div className="rounded-circle position-absolute chat-offline head-messaging"></div>
          )}
        </div>
        <div className="d-flex justify-content-center flex-column align-items-start">
          <div
            style={{
              textTransform: 'capitalize',
              color: '#FFFFFF',
              fontWeight: 700,
              fontSize: 16,
              textDecoration: 'none',
            }}
            onMouseOver={handleMouseOverName}
            onMouseOut={handleMouseOutName}
            className={classNames({ 'text-underline': onHoverName })}
          >
            {_.capitalize(_.get(rsusers, [selectedChatUser?.name, 'name']))}
          </div>
          <div style={{ fontSize: 12, fontWeight: 400, color: '#B2B2B2' }}>
            {_.get(rsusers, [selectedChatUser?.name, 'school_name'])}
          </div>
        </div>
      </div>
    </a>
  )
}

const prepareDefaultValue = (rid: string) => ({
  _id: v4(),
  rid: rid,
  msg: '',
})

const MessageList: FC<MessageListProps> = (props) => {
  const { open, toggle } = props
  const scrollRef = useRef<any>(null)
  const textMessage = useRef<any>(null)
  const [focusMsg, setFocusMsg] = useState('')
  const [lightBoxOpened, setLightBoxOpened] = useState(false)
  const [allowDisplay, setAllowDisplay] = useState(true)
  const [selectImageSrc, setSelectImageSrc] = useState('')
  const [selectVideoSrc, setSelectVideoSrc] = useState('')
  const dispatch = useDispatch()
  // const [open, toggle] = useToggle(false)
  const [deleteMessage, setDeleteMessage] = useState(null)
  const [deletedMessages, { push }] = useList<string>([])
  const {
    rocketChatState: {
      selectedRoomChat,
      roomChatHistory,
      userPresenceList,
      selectedRoomChatLoading,
      rsusers,
      rcCurrentLoginInfo: { data: rcCurrentLoginInfo },
    },
  } = useSelector((state: any) => state)

  const [openDiaLog, setOpenDiaLog] = React.useState(false)

  const handleClickOpen = () => {
    setOpenDiaLog(true)
  }

  const handleClose = () => {
    setOpenDiaLog(false)
  }

  const selectedUsername =
    (_.get(rsusers, [selectedRoomChat?.name, 'name']), selectedRoomChat?.name)
  const selectedUser = {
    target_group_id: rsusers[selectedUsername]?.target_group_id,
    profile_id: rsusers[selectedUsername]?.profile_ref_id,
    name: selectedUsername,
  }
  const urlSelectedProfile = `${process.env.REACT_APP_ROSTERSPOT_APP_URL}/profile/${selectedUser.target_group_id}/${selectedUser.profile_id}/${selectedUser.name}/timeline`

  const openFullImage = (imageSrc: string) => {
    setSelectImageSrc(imageSrc)
    setLightBoxOpened(true)
    setSelectVideoSrc('')
    setAllowDisplay(true)
  }

  const openFullVideo = (videoSrc: string) => {
    setSelectVideoSrc(videoSrc)
    setLightBoxOpened(true)
    setSelectImageSrc('')
    setAllowDisplay(true)
  }

  const setContentDisplay = (param) => {
    switch (param.type) {
      case 'video':
        return openFullVideo(param.data.videoURL)
      case 'photo':
        return openFullImage(param.data.uri)
      case 'text':
        return setAllowDisplay(false)
      case 'file':
        return window.open(param.data.uri)
    }
  }

  const { register, reset, handleSubmit, getValues } = useForm({
    defaultValues: prepareDefaultValue(''),
    resolver: yupResolver(
      yup.object({
        msg: yup.string().required().nullable(),
        rid: yup.string().required().nullable(),
        _id: yup.string().required().nullable(),
      })
    ),
  })

  useEffect(() => {
    reset(prepareDefaultValue(selectedRoomChat?.rid))
    scrollSmoothToBottom()

    const timer = setTimeout(() => {
      textMessage.current.focus()
    }, 100)

    return () => {
      clearTimeout(timer)
    }
  }, [selectedRoomChat])

  useEffect(() => {
    _.set(window, 'chatScroll.scrollToBottom', scrollSmoothToBottom)
    scrollSmoothToBottom()
  }, [])

  const onScrollFrame = (scrollTop: number, cb?: () => void) => {
    if (_.inRange(scrollTop, 0.8)) {
      const firstMessage: any = _.first(roomChatHistory[selectedRoomChat.rid])
      const lastMessage: any = _.last(roomChatHistory[selectedRoomChat.rid])
      const { ts: from } = firstMessage ?? { ts: { $date: moment().unix() } }
      const { ts: to } = lastMessage ?? { ts: { $date: moment().unix() } }
      dispatch(rocketChatActions.loadHistory(from, to, cb))
    }
  }

  const submit = handleSubmit((params, event) => {
    const handleMessage = params.msg.trim()
    if (handleMessage) {
      dispatch(rocketChatActions.sendMessage(params))
      reset(prepareDefaultValue(selectedRoomChat?.rid))
      scrollSmoothToBottom()
    } else {
      textMessage.current.value = ''
    }
  })

  const scrollSmoothToBottom = () => {
    $('.rce-mlist-down-button').click()
  }

  const renderOnline = (name: string) =>
    _.some(
      userPresenceList,
      (presence) => presence.status === 'online' && presence.username === name
    )
      ? 'Online'
      : 'Offline'

  const [{ value: searchResults }, handleSearchMessage] = useAsyncFn(
    rocketChatServices.searchMessageInRoom
  )

  const handleOnRemoveMessageClick = (message: any) => {
    setDeleteMessage({ ...message })
  }

  const handleAfterDeleteMessage = (message: any) => {
    push(message.id as string)
    setDeleteMessage(null)
  }

  const getMessages = (roomChatHistory, selectedRoomChat) => {
    return _.unionBy(
      _.get(roomChatHistory, selectedRoomChat?.rid, []),
      '_id'
    ).filter((item: any) => !_.includes(deletedMessages, item._id))
  }

  const meUsername =
    (_.get(rsusers, [rcCurrentLoginInfo?.me?.username, 'username']),
    rcCurrentLoginInfo?.me?.username)
  let getMeUser = getMessages(roomChatHistory, selectedRoomChat).map(
    (item: any) => {
      if (item.u._id === rcCurrentLoginInfo.userId) {
        const meUser = {
          target_group_id: rsusers[meUsername]?.target_group_id,
          profile_id: rsusers[meUsername]?.profile_ref_id,
          name: rsusers[meUsername]?.name,
        }
        return meUser
      }
    }
  )
  getMeUser = _.compact(getMeUser)
  const urlMeProfile = `${process.env.REACT_APP_ROSTERSPOT_APP_URL}/profile/${getMeUser[0]?.target_group_id}/${getMeUser[0]?.profile_id}/${getMeUser[0]?.name}/timeline`

  const handleFocusMessage = async (messageId: string) => {
    let messages = _.get(roomChatHistory, selectedRoomChat?.rid, [])
    while (!_.some(messages, (item) => item._id === messageId)) {
      let lastMessage: any = _.last(messages)
      const newNessages = await rocketChatServices.loadHistory(
        selectedRoomChat?.rid,
        _.get(lastMessage, 'ts.$date'),
        moment().unix()
      )
      messages = _.concat(messages, newNessages)
      dispatch(
        rocketChatActions.prependRoomChatHistory(
          selectedRoomChat?.rid,
          messages
        )
      )
    }
    setFocusMsg(messageId)
  }

  const handleOnDowladFile = (e: any) => {
    let messages = _.get(roomChatHistory, selectedRoomChat?.rid, [])
    const message = _.find(messages, (item) => item._id === e.id)
    if (message && _.has(message, 'attachments[0].title_link'))
      window.open(
        urlcat(
          process.env.REACT_APP_ROCKETCHAT_API_BASE_URL!,
          _.get(message, 'attachments[0].title_link')
        )
      )
  }

  const handleToggleSearchChat = () => {
    return toggle((prev) => !prev)
  }

  return (
    <div className="d-flex flex-column h-100 flex-fill">
      <Paper
        variant="elevation"
        className={classNames(
          'd-flex flex-column justify-conten-between flex-fill',
          { 'd-none': !Boolean(selectedRoomChat) }
        )}
      >
        <Card
          className="d-flex justify-content-between p-2 align-items-center header-user-info"
          style={{
            borderEndStartRadius: 0,
            borderEndEndRadius: 0,
            backgroundColor: '#2E2E2E',
          }}
        >
          {_.isEmpty(_.get(rsusers, selectedRoomChat?.name)) ? (
            <UserSkeleton />
          ) : (
            <Person
              selectedChatUser={selectedRoomChat}
              status={renderOnline(selectedRoomChat?.name)}
            />
          )}
          <div className="box-icon-header">
            <div className="btn-delete">
              <IconButton aria-label="delete" onClick={handleClickOpen}>
                <img src="/images/chat-recycle.svg" />
              </IconButton>
              <Dialog
                className="dialog-delete"
                open={openDiaLog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <div className="popup-delete">
                  <div className="d-flex align-items-center">
                    <img src="/images/icon-why.svg" />
                    <DialogTitle
                      id="alert-dialog-title"
                      style={{
                        fontSize: 14,
                        padding: 0,
                        lineHeight: 'unset',
                        paddingLeft: 8,
                      }}
                    >
                      {'Are you sure?'}
                    </DialogTitle>
                  </div>
                  <DialogContent
                    style={{
                      padding: '20px 35px',
                    }}
                  >
                    <DialogContentText
                      id="alert-dialog-description"
                      style={{
                        color: '#ffffff',
                        fontSize: 14,
                      }}
                    >
                      Post will be deleted!
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions style={{ padding: 0 }}>
                    <Button
                      onClick={handleClose}
                      variant="outlined"
                      style={{
                        border: '1px solid #a9a9a9',
                        color: '#a9a9a9',
                        fontSize: 12,
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleClose}
                      color="secondary"
                      variant="contained"
                      autoFocus
                      style={{
                        fontSize: 12,
                        background: '#f50057',
                      }}
                    >
                      Delete
                    </Button>
                  </DialogActions>
                </div>
              </Dialog>
            </div>

            <IconButton onClick={handleToggleSearchChat} className="unpadding">
              {open ? (
                <Close style={{ color: '#ffffff' }} />
              ) : (
                <img src="/images/search.svg" />
              )}
            </IconButton>
          </div>
        </Card>
        <div
          className="d-flex flex-fill msg-container"
          style={{ position: 'relative' }}
        >
          {selectedRoomChatLoading && (
            <div className="h-100 align-items-center d-flex justify-content-center">
              <CircularProgress />
            </div>
          )}
          <div
            className={classNames(
              'd-flex flex-column justify-content-end align-items-center flex-fill ms-chat-items  pb-2 bg-chat',
              { 'w-50': open }
            )}
            id="scroll-chat"
            ref={scrollRef}
            onScroll={(e) =>
              onScrollFrame(
                e.currentTarget.scrollTop / e.currentTarget.clientHeight
              )
            }
          >
            <ReactChatElements
              onRemoveMessageClick={handleOnRemoveMessageClick}
              onMessageFocused={(e) => console.log({ e })}
              style={{ height: 400, overflow: 'auto' }}
              className="react-message-list"
              id="scroll-chat"
              onScroll={(e) =>
                onScrollFrame(
                  e.currentTarget.scrollTop / e.currentTarget.clientHeight
                )
              }
              lockable={true}
              onDownload={handleOnDowladFile}
              onClick={(item) => {
                setContentDisplay(item)
              }}
              onTitleClick={(item) => {
                if (item.position === 'right') {
                  window.open(urlMeProfile, '_blank')
                } else {
                  window.open(urlSelectedProfile, '_blank')
                }
                setTimeout(() => {
                  setLightBoxOpened(false)
                }, 100)
              }}
              dataSource={getMessages(roomChatHistory, selectedRoomChat)
                .map((item: any) => {
                  const textTypeFileUpload = _.includes(item.file?.type, 'text')
                  const applicationTypeFileUpload = _.includes(
                    item.file?.type,
                    'application'
                  )
                  const hasUrl = _.some(item.urls)
                  const urlItem: any = item.urls[0]
                  const allowRenderImage =
                    Boolean(_.get(urlItem, 'meta.ogImage')) ||
                    Boolean(_.get(urlItem, 'meta.oembedThumbnailUrl'))
                  let hasRenderImage = false
                  let hasRenderVideo = false
                  let props: any = {
                    position:
                      item.u._id !== rcCurrentLoginInfo.userId
                        ? 'left'
                        : 'right',
                    type: 'text',
                    data: {},
                    text: item.msg,
                    id: item._id,
                    date: moment.utc(item.ts.$date).local().toDate(),
                    focus: focusMsg === item._id,
                    removeButton: item.u._id === rcCurrentLoginInfo.userId,
                    avatar:
                      _.get(
                        rsusers,
                        [item.u.username, 'profile_image_url'],
                        process.env.REACT_APP_DEFAULT_AVATAR
                      ) === 'undefined'
                        ? `${process.env.REACT_APP_ROSTERSPOT_API_BASE_URL}/static/img/user-male-circle-filled.png`
                        : _.get(
                            rsusers,
                            [item.u.username, 'profile_image_url'],
                            process.env.REACT_APP_DEFAULT_AVATAR
                          ),
                    // title: _.get(
                    //   rsusers,
                    //   [item.u.username, 'name'],
                    //   item.u.name
                    // ),
                  }

                  if (textTypeFileUpload || applicationTypeFileUpload) {
                    let attachment = _.get(item, 'attachments[0]')
                    props.data = {
                      uri: urlcat(
                        process.env.REACT_APP_ROCKETCHAT_API_BASE_URL!,
                        _.get(item, 'attachments[0].title_link')
                      ),
                    }
                    props.text = (
                      <div
                        className="file-chat-item"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <small
                          style={{
                            fontWeight: 700,
                            fontSize: 12,
                            color: '#105898',
                          }}
                        >
                          {attachment.title}
                        </small>
                        {attachment.description && (
                          <div>{attachment.description}</div>
                        )}
                      </div>
                    )
                    props.type = 'file'
                  }

                  if (
                    _.some(item.attachments, (att) => _.has(att, 'image_type'))
                  ) {
                    hasRenderImage = true
                    let attachment = _.get(item, 'attachments[0]')
                    props.type = 'photo'
                    props.text = (
                      <div className="photo-chat-item">
                        <small style={{ fontWeight: 700, fontSize: 12 }}>
                          {attachment.title}
                        </small>
                        {attachment.description && (
                          <div>{attachment.description}</div>
                        )}
                      </div>
                    )
                    props.data = {
                      uri: urlcat(
                        process.env.REACT_APP_ROCKETCHAT_API_BASE_URL!,
                        _.get(item, 'attachments[0].image_url')
                      ),
                      width: '100%',
                      height: 'auto',
                      alt: item.msg,
                      status: {
                        download: true,
                      },
                    }
                  }
                  if (
                    _.some(item.attachments, (att) => _.has(att, 'video_url'))
                  ) {
                    hasRenderVideo = true
                    const attachment = _.get(item, 'attachments[0]')
                    props.type = 'video'
                    props.text = (
                      <div className="video-chat-item">
                        <small
                          style={{
                            fontWeight: 700,
                            fontSize: 12,
                            color: '#309CFF',
                          }}
                        >
                          {attachment.title}
                        </small>
                        {attachment.description && (
                          <div>{attachment.description}</div>
                        )}
                      </div>
                    )
                    props.data = {
                      videoURL: urlcat(
                        process.env.REACT_APP_ROCKETCHAT_API_BASE_URL!,
                        _.get(item, 'attachments[0].video_url')
                      ),
                      width: '100%',
                      height: 'auto',
                      alt: item.msg,
                      status: {
                        download: true,
                      },
                    }
                  }
                  if (
                    hasUrl &&
                    !_.includes(item.msg, '${image}') &&
                    !_.includes(item.msg, '${video}')
                  ) {
                    let srcImage =
                      _.get(urlItem, 'meta.ogImage', '') ||
                      _.get(urlItem, 'meta.oembedThumbnailUrl', '')
                    const formatSrcImage = _.includes(srcImage, 'http')
                    if (!formatSrcImage) {
                      srcImage = _.join([urlItem.url, srcImage], '')
                    }
                    const pageTitle =
                      _.get(urlItem, 'meta.pageTitle', '') ||
                      _.get(urlItem, 'meta.oembedTitle', '')
                    const hasMeta = Boolean(urlItem.meta)
                    const text = _.join(
                      _.get(item, 'md[0].value')
                        .filter((x) => x.type === 'PLAIN_TEXT')
                        .map((x) => x.value)
                        .filter(Boolean)
                    )
                    props.text = (
                      <div className="url-chat-item">
                        <div className="url-chat-text">{text}</div>
                        <a target="_blank" href={urlItem.url}>
                          {urlItem.url}
                        </a>
                        {hasMeta && (
                          <div className="url-meta">
                            <img
                              className="img-fluid"
                              src={srcImage}
                              alt={urlItem.meta.pageTitle}
                            />
                            <div className="url-chat-item-title">
                              {pageTitle}
                            </div>
                            <small className="url-chat-item-desc">
                              {urlItem.meta.ogDescription}
                            </small>
                          </div>
                        )}
                      </div>
                    )
                  }
                  if (
                    hasUrl &&
                    !_.includes(item.msg, '${image}') &&
                    !_.includes(item.msg, '${video}') &&
                    !allowRenderImage
                  ) {
                    const pageTitle =
                      _.get(urlItem, 'meta.pageTitle', '') ||
                      _.get(urlItem, 'meta.oembedTitle', '')
                    const hasMeta = Boolean(urlItem.meta)
                    const text = _.join(
                      _.get(item, 'md[0].value')
                        .filter((x) => x.type === 'PLAIN_TEXT')
                        .map((x) => x.value)
                        .filter(Boolean)
                    )
                    props.text = (
                      <div className="url-chat-item">
                        <div className="url-chat-text">{text}</div>
                        <a target="_blank" href={urlItem.url}>
                          {urlItem.url}
                        </a>
                        {hasMeta && (
                          <div className="url-meta">
                            <div className="url-chat-item-title">
                              {pageTitle}
                            </div>
                            <small className="url-chat-item-desc">
                              {urlItem.meta.ogDescription}
                            </small>
                          </div>
                        )}
                      </div>
                    )
                  }
                  if (!hasRenderImage && _.includes(item.msg, '${image}')) {
                    const textArray = _.split(item.msg, '${image}')
                    const imageLink = textArray.filter((str) =>
                      helpers.isURL(str)
                    )
                    let attachment = {
                      title: textArray[1],
                      // .filter((str) => !helpers.isURL(str))
                      // .join(),
                      description: textArray[0],
                    }
                    props.type = 'photo'
                    props.text = (
                      <div className="photo-chat-item">
                        <small style={{ fontWeight: 700, fontSize: 12 }}>
                          {attachment.title}
                        </small>
                        <div className="description-item-video-img">
                          {attachment.description && (
                            <div>{attachment.description}</div>
                          )}
                        </div>
                      </div>
                    )
                    props.data = {
                      uri: textArray[1],
                      width: '100%',
                      height: 'auto',
                      alt: attachment.description,
                      status: {
                        download: true,
                      },
                    }
                  }
                  if (!hasRenderVideo && _.includes(item.msg, '${video}')) {
                    const textArray = _.split(item.msg, '${video}')
                    const imageLink = textArray.filter((str) =>
                      helpers.isURL(str)
                    )

                    let attachment = {
                      title: textArray[1],
                      // .filter((str) => !helpers.isURL(str))
                      // .join(),
                      description: textArray[0],
                    }

                    props.type = 'video'
                    props.text = (
                      <div className="photo-chat-item">
                        <small style={{ fontWeight: 700, fontSize: 12 }}>
                          {attachment.title}
                        </small>
                        <div className="description-item-video-img">
                          {attachment.description && (
                            <div>{attachment.description}</div>
                          )}
                        </div>
                      </div>
                    )
                    props.data = {
                      videoURL: textArray[1],
                      width: '100%',
                      height: 'auto',
                      alt: attachment.description,
                      status: {
                        download: true,
                      },
                    }
                  }
                  if (otherFiles.includes(item.file?.type)) {
                    props.type = 'file'
                    props.data = {
                      status: {},
                    }
                  }
                  if (
                    !hasUrl &&
                    !_.includes(item.msg, '${image}') &&
                    !_.includes(item.msg, '${video}') &&
                    helpers.isURL(item.msg)
                  )
                    props.text = (
                      <div className="url-chat-item">
                        <a target="_blank" href={item.msg}>
                          {item.msg}
                        </a>
                      </div>
                    )

                  return {
                    className: classNames('msg-item', {
                      'has-photo': props.type === 'photo',
                      'has-link': hasUrl,
                    }),
                    ...props,
                  }
                })
                .reverse()}
            />
          </div>
          {open && (
            <Card className="d-flex flex-column justify-content-start align-items-center flex-fill p-2 rounded-0 w-25 message-search-keyword">
              <div className="p-2 w-100">
                <SearchControl
                  onSearch={(textSearch) =>
                    handleSearchMessage(
                      selectedRoomChat.rid,
                      selectedRoomChat.u._id,
                      textSearch
                    )
                  }
                />
              </div>
              <div className="my-2 w-100 list-comment-search">
                {_.map(searchResults, (item: any) => {
                  return (
                    <div className="w-100 list-comment-search_item">
                      <ChatListItem
                        onClick={() => {
                          handleFocusMessage(item._id)
                          toggle()
                        }}
                        timestamp={item.ts.$date}
                        item={{
                          name: item.u.name,
                          lastMessage: item.msg,
                        }}
                        urlProfile={false}
                      />
                    </div>
                  )
                })}
              </div>
            </Card>
          )}
        </div>
        <form onSubmit={submit} className="msg-textfield box-shawdow-top">
          <input type="text" {...register('_id')} style={{ display: 'none' }} />
          <input type="text" {...register('rid')} style={{ display: 'none' }} />
          <div className="write-message">
            <CssTextField
              id="outlined-basic"
              variant="outlined"
              inputProps={register('msg')}
              inputRef={textMessage}
              fullWidth
              multiline
              maxRows={4}
              onFocus={() => {
                dispatch(rocketChatActions.readMessages(selectedRoomChat.rid))
              }}
              placeholder="Write a message..."
              className="msg-input input_message"
              InputLabelProps={{ shrink: true }}
              onKeyPress={(e) => e.key === 'Enter' && submit(e)}
              InputProps={{
                endAdornment: (
                  <div className="d-flex">
                    <button onClick={() => submit()} className="btn-send">
                      <img src="/images/send-chat.svg" alt="send chat" />
                    </button>
                  </div>
                ),
              }}
            />
            <div className="d-flex">
              <RocketChatUpload selectedRoomChat={selectedRoomChat} />
            </div>
          </div>
        </form>
      </Paper>
      <Paper
        variant="elevation"
        className={classNames(
          'd-flex flex-column justify-content-center align-items-center flex-fill bg-chat',
          { 'd-none': Boolean(selectedRoomChat) }
        )}
      >
        <Card
          className="start-messaging py-1 px-3 rounded-pill"
          style={{
            color: '#FFFFFF',
          }}
        >
          <img src="/images/box-item.svg" alt="box item"></img>
          <p style={{ color: '#9d9d9d', fontSize: 16, paddingTop: 27 }}>
            Select a chat to start messaging
          </p>
        </Card>
      </Paper>
      <DeleteMessageDialog
        onClose={() => setDeleteMessage(null)}
        affterDeleted={handleAfterDeleteMessage}
        message={deleteMessage}
      />
      <div style={{ display: 'none' }}>
        <Modal
          className="image-chat"
          style={{
            maxHeight: '100vh',
            maxWidth: '100vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          open={lightBoxOpened}
          onClose={() => setLightBoxOpened(false)}
        >
          <Box className="full-image-chat">
            {allowDisplay ? (
              selectImageSrc ? (
                <img
                  src={selectImageSrc}
                  style={{ maxWidth: '80vw', maxHeight: '80vh' }}
                />
              ) : (
                <video
                  controls
                  autoPlay
                  style={{
                    maxHeight: '80vh',
                    maxWidth: '80vw',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <source src={selectVideoSrc}></source>
                </video>
              )
            ) : (
              <div></div>
            )}
            <Tooltip title="Download">
              <IconButton
                aria-label="download picture"
                component="span"
                style={{
                  position: 'absolute',
                  right: '2%',
                  top: '2%',
                  color: 'rgba(255,255,255, 0.87 )',
                  fontWeight: 'bold',
                }}
                onClick={() =>
                  window.open(selectImageSrc ? selectImageSrc : selectVideoSrc)
                }
              >
                <DownloadIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </Box>
        </Modal>
      </div>
    </div>
  )
}

export default MessageList
