import rocketChatActions from '@stores/actions/rocketChatActions'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ChatContact from './components/ChatContact'
import ChatList from './components/ChatList'
import ChatMessage from './components/ChatMessage'
import SnackbarMessage from './components/SnackbarMessage'

const ChatAppContainer = () => {
  const dispatch = useDispatch()
  const {
    rocketChatLoginInfo: {
      data: { userId },
    },
  } = useSelector((state) => _.get(state, 'meState', {}))

  const [open, toogle] = useState(false)

  useEffect(() => {
    dispatch(rocketChatActions.getSubscriptions())
  }, [userId])

  return (
    <React.Fragment>
      <div className="chat">
        <div className="chat-wrapper row md-0">
          <div className="col-xl-3 p-0 d-flex justify-content-end">
            <ChatList openSearch={open} toggleSearch={toogle}/>
          </div>
          <div className="col-12 col-xl-6 p-0 unborder-box-chat">
            <ChatMessage open={open} toggle={toogle}/>
            <SnackbarMessage />
          </div>
          <div className="col-xl-3 p-0">
            <ChatContact />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ChatAppContainer
