import axios from "axios";
import _ from "lodash";
import * as ls from 'local-storage'

const rocketchatApiRequest = axios.create({
  baseURL: `${process.env.REACT_APP_ROCKETCHAT_API_BASE_URL}/api`,
});

rocketchatApiRequest.interceptors.request.use(
  function (config) {
    config.headers.common["X-Auth-Token"] = ls.get("rocketchat.authToken")
    config.headers.common["X-User-Id"] = ls.get("rocketchat.userId")
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

rocketchatApiRequest.interceptors.response.use(
  (response) => _.get(response, 'data'),
  (error) => {
    return Promise.reject(error);
  }
);

export default rocketchatApiRequest;
