import layoutActions from "@stores/actions/layoutActions";

export interface LayoutState {
  activeTabId: number
  tabs: number[]
}

const initialState: LayoutState = {
  activeTabId: 0,
  tabs: [0, 1, 2]
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: any) => {
  switch (action.type) {
    case layoutActions.actionTypes.SET_CHAT_ACTIVE_TAB:
      return {
        ...state,
        activeTabId: action.activeTabId
      }

    default:
      return state
  }
}