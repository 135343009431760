import React, { FC, useEffect, useState } from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
  return (
    <Box style={{width: "100%"}} sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress className='inProgress-upload-chat' style={{borderRadius: 10, height: 6}} variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 100 }}>
        <Typography variant="body2" color="text.white">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  )
}

export default LinearProgressWithLabel