import moment from 'moment'

const isSameDay = (currentTimeString, diffTimeString) => {
    if (!currentTimeString || !diffTimeString) {
        return false
    }
    const currentMoment = moment(currentTimeString)
    const diffMoment = moment(diffTimeString)
    if (!currentMoment.isValid() || !diffMoment.isValid()) {
        return false
    }
    return currentMoment.isSame(diffMoment, 'day')
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    isSameDay
}