import { FC } from 'react'
import { Avatar, Badge, Card, CardContent, Typography } from '@material-ui/core'
import RocketUtil from '@utils/rocketUtil'
import classNames from 'classnames'
import _ from 'lodash'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Button, IconButton } from '@mui/material'

interface ChatItem {
  name: string
  unreadCount?: number
  lastMessage?: string
  avatar?: string
  target?: number
  profileId?: number
}

interface ChatListItemProps {
  timestamp?: string
  item: ChatItem
  selected?: boolean
  online?: boolean
  onClick?: () => void
  loading?: boolean
  flat?: string
  messageChat?: boolean
  urlProfile?: boolean
}

const ChatListItem: FC<ChatListItemProps> = ({
  item,
  selected,
  onClick,
  online,
  timestamp,
  loading,
  flat,
  messageChat,
  urlProfile,
}) => {
  const urlSelectedProfile = `${process.env.REACT_APP_ROSTERSPOT_APP_URL}/profile/${item.target}/${item.profileId}/${item.name}/timeline`;
  return (
    <Card
      style={{ overflow: 'hidden' }}
      className={classNames('m-2 chat-item box-item-chat_child', {
        selected: selected,
      })}
      onClick={onClick}
    >
      <div
        className={classNames('person-info', {
          'person-info-flat': flat === 'flat',
        })}
      >
        <CardContent className="d-flex p-2">
          <Badge
            badgeContent={item?.unreadCount}
            color="secondary"
            className="unread"
          >
            {loading ? (
              <SkeletonTheme baseColor="#D3D3D3" highlightColor="#FFFFFF">
                <Skeleton
                  count={1}
                  duration={2}
                  circle={true}
                  borderRadius={1}
                  width={34}
                  height={34}
                />
              </SkeletonTheme>
            ) : !urlProfile ? (
              <p style={{ textDecoration: 'none' }}>
                <Avatar
                  src={
                    item.avatar === '' || item.avatar === 'undefined'
                      ? `${process.env.REACT_APP_ROSTERSPOT_API_BASE_URL}/static/img/user-male-circle-filled.png`
                      : item.avatar
                  }
                  className={classNames(
                    'me-2 avatart-chat border-avatart-chat',
                    {
                      'position-relative': online,
                    }
                  )}
                />
              </p>
            ) : (
              <a
                href={urlSelectedProfile}
                target="_blank"
                style={{ textDecoration: 'none' }}
              >
                <Avatar
                  src={
                    item.avatar === '' || item.avatar === 'undefined'
                      ? `${process.env.REACT_APP_ROSTERSPOT_API_BASE_URL}/static/img/user-male-circle-filled.png`
                      : item.avatar
                  }
                  className={classNames(
                    'me-2 avatart-chat border-avatart-chat',
                    {
                      'position-relative': online,
                    }
                  )}
                />
              </a>
            )}
            {!loading &&
              (online ? (
                <div className="rounded-circle position-absolute chat-online"></div>
              ) : (
                <div className="rounded-circle position-absolute chat-offline"></div>
              ))}
          </Badge>
          <div className="flex-fill">
            <div className="contact-user-info">
              {loading ? (
                <SkeletonTheme baseColor="#D3D3D3" highlightColor="#FFFFFF">
                  <div className="d-flex justify-content-start ps-2 pt-1">
                    <Skeleton count={1} duration={2} width={80} height={10} />
                  </div>
                </SkeletonTheme>
              ) : !urlProfile ? (
                <div style={{ textDecoration: 'none' }}>
                  <Typography className="user-info" align="left">
                    {item.name}
                  </Typography>
                </div>
              ) : (
                <a
                  href={urlSelectedProfile}
                  style={{ textDecoration: 'none' }}
                  target="_blank"
                >
                  <Typography className="user-info" align="left">
                    {item.name}
                  </Typography>
                </a>
              )}
              {flat ? <img src="/images/flat-icon-chat.svg" /> : ''}
            </div>
            {loading ? (
              <SkeletonTheme baseColor="#D3D3D3" highlightColor="#FFFFFF">
                <div className="d-flex justify-content-start ps-2">
                  <Skeleton count={1} duration={2} width={200} height={10} />
                </div>
              </SkeletonTheme>
            ) : (
              <Typography className="message-info" align="left">
                {typeof item.lastMessage !== 'string'
                  ? item?.lastMessage
                  : _.truncate(item?.lastMessage ?? 'No message', {
                      length: 30,
                    })}
                &nbsp;
              </Typography>
            )}
          </div>
          <div>
            {loading ? (
              <SkeletonTheme baseColor="#D3D3D3" highlightColor="#FFFFFF">
                <Skeleton count={1} duration={2} width={40} height={10} />
              </SkeletonTheme>
            ) : (
              <Typography className="times-info">
                <small>
                  {timestamp && RocketUtil.lastUpdateString(timestamp)}
                </small>
              </Typography>
            )}
          </div>
        </CardContent>
        {messageChat ? (
          <IconButton className="icon-chat">
            <img src="/images/message-icon.svg" alt="message-icon" />
          </IconButton>
        ) : (
          ''
        )}
      </div>
    </Card>
  )
}

export default ChatListItem
