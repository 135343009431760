import axios from "axios"
import sessionServices from "./sessionServices"

const rsApiRequest = axios.create({
  baseURL: `${process.env.REACT_APP_ROSTERSPOT_API_BASE_URL}/mobile/v2`,
})

rsApiRequest.interceptors.request.use(
  function (config) {
    config.headers.common["Authorization"] = sessionServices.getAuthToken()
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

rsApiRequest.interceptors.response.use(
  (response) => {
    let rs = response.data
    if (response.data.result) {
      rs = response.data.result
      rs.isOk = true
    }

    return rs
  },
  (error) => {
    if (error.response && error.response.data) {
      let { data } = error.response
      data.isOk = false
      return data
    }

    return Promise.reject(error)
  }
)

export default rsApiRequest
