import rsApiRequestV3 from "@services/rsApiRequestV3";
import _ from "lodash";
import { v4 } from "uuid";
import rocketChatClient from "../rocketchatApiRequest";
import { FileMessage, LoginResult, MessageAttachment } from "./dtos";
import { RoomInfo, RoomResult, UserPresenseResult } from "./roomDtos";
import { SubscriptionResult } from "./subscriptionDtos";
import { excel } from './excel.json'
import { word } from './word.json'
import { video } from './video.json'

const sendFileMessage = (rid: string, file: File, message: FileMessage): Promise<MessageAttachment> => {
  let formData = new FormData()
  formData.append('file', file)
  formData.append('msg', message.msg ?? "")
  formData.append('description', message.description ?? "")

  return rocketChatClient.post(`v1/rooms.upload/${rid}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

const imageToBase64 = (files: any): Promise<{ source: string, msg?: string, imageFile: File }> => {
  return new Promise((resolve, reject) => {
    try {
      const file = files[0]
      if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        return resolve({
          imageFile: file,
          source: excel,
          msg: file.name
        })

      if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
        return resolve({
          imageFile: file,
          source: word,
          msg: file.name
        })

      if (_.includes(file.type, 'video'))
        return resolve({
          imageFile: file,
          source: video,
          msg: file.name
        })

      var reader = new FileReader()
      reader.onloadend = function () {
        resolve({
          imageFile: file,
          source: reader.result as string,
          msg: file.name
        })
      }

      reader.readAsDataURL(file);
    } catch (error) {
      reject(error)
    }
  })
}

const login = (user: string, password: string): Promise<LoginResult> =>
  rocketChatClient.post('v1/login', { user, password })

const getSubscriptions = (params?: any[]): Promise<SubscriptionResult> =>
  rocketChatClient.post('v1/method.call/subscriptions.get', {
    message: JSON.stringify({
      msg: "method",
      method: "subscriptions/get",
      params: params ?? [],
      id: v4()
    })
  }).then((data: any) => {
    return Promise.resolve<SubscriptionResult>({
      message: JSON.parse(data.message) as any,
      success: data.success
    })
  })

const getRooms = (): Promise<RoomResult> =>
  rocketChatClient.post('v1/method.call/rooms.get', {
    message: JSON.stringify({
      msg: "method",
      method: "rooms/get",
      params: [],
      id: v4()
    })
  }).then((data: any) => {
    return Promise.resolve<RoomResult>({
      message: JSON.parse(data.message) as any,
      success: data.success
    })
  })

const getUserPresence = (rocketchat_user_ids: string[]): Promise<UserPresenseResult> =>
  rocketChatClient.get('v1/users.presence', {
    params: {
      ids: rocketchat_user_ids
    }
  })

const getOneSubscription = (rid: string) =>
  rocketChatClient.get("v1/subscriptions.getOne", {
    params: {
      roomId: rid
    }
  })

const getRoomInfo = (rid: string): Promise<RoomInfo> =>
  rocketChatClient.get('v1/rooms.info', {
    params: {
      roomId: rid
    }
  }).then((data: any) => data.room)

const searchMessageInRoom = (rid: string, uid: string, textSearch: string, searchAll: boolean = false): Promise<any> =>
  rocketChatClient.post('v1/method.call/rocketchatSearch.search', {
    message: JSON.stringify({
      msg: "method",
      method: "rocketchatSearch.search",
      params: [
        textSearch,
        {
          rid
        },
        {
          searchAll
        }
      ],
      id: v4()
    })
  }).then((data: any) => _.get(JSON.parse(data.message), 'result.message.docs', []))

const deleteMessage = (_id: string): Promise<any> =>
  rocketChatClient.post('v1/method.call/deleteMessage', {
    message: JSON.stringify({
      msg: "method",
      method: "deleteMessage",
      params: [
        {
          _id
        }
      ],
      id: v4()
    })
  }).then((data: any) => _.get(JSON.parse(data.message), 'result.message'))

const fetchRosterUsers = (rocketchat_usernames: string[]) =>
  rsApiRequestV3.post('rocketchat/account.fetchUsers', rocketchat_usernames)

const loadHistory = (rid: string, fromDate: number, toDate: number): Promise<any> => {
  return rocketChatClient.post('v1/method.call/loadHistory', {
    message: JSON.stringify({
      msg: "method",
      method: "loadHistory",
      params: [
        rid,
        { $date: fromDate },
        100,
        { $date: toDate },
        false
      ],
      id: v4()
    })
  }).then((data: any) => {
    const message = JSON.parse(data.message)
    return _.get(message, 'result.messages')
  })
}


export default {
  loadHistory,
  fetchRosterUsers,
  getUserPresence,
  getRooms,
  getSubscriptions,
  sendFileMessage,
  imageToBase64,
  login,
  getOneSubscription,
  getRoomInfo,
  searchMessageInRoom,
  deleteMessage
}