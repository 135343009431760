import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  makeStyles,
  TextField,
} from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import rocketChatServices from '@services/rocketChatServices'
import rocketChatActions from '@stores/actions/rocketChatActions'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAsyncFn } from 'react-use'
import _ from 'lodash'
import LinearProgressWithLabel from '@components/LinearProgressWithLabel'
import rocketChatClient from '@services/rocketchatApiRequest'
import axios, { CancelToken } from 'axios'
import Box from '@mui/material/Box'

import { styled } from '@mui/material/styles'

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#9E9E9E',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'transparent',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'transparent',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
})

const useStyles = makeStyles((theme: any) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}))

interface RocketChatUploadProps {
  selectedRoomChat?: any
}

const RocketChatUpload: FC<RocketChatUploadProps> = ({ selectedRoomChat }) => {
  const dispatch = useDispatch()
  const [previewOpen, setPreviewOpen] = useState(false)
  const classes = useStyles()
  const [description, setDescription] = useState('  ')
  const [{ loading }, sendFileMessageFn] = useAsyncFn(
    rocketChatServices.sendFileMessage
  )
  const [previewState, previewFn] = useAsyncFn(rocketChatServices.imageToBase64)
  const [srcVideo, setSrcVideo] = useState('')
  const [uploadPercent, setUploadPercent] = useState(0)
  const [allowDisplayLoading, setAllowDisplayLoading] = useState(false)
  const [displayImage, setDisplayImage] = useState(true)
  const cancelFileUpload = useRef(() => {})
  const CancelToken = axios.CancelToken

  useEffect(() => {
    setPreviewOpen(Boolean(previewState.value))
    setDescription('')
  }, [previewState.value])

  const setContentDisplay = (event) => {
    let file = event.target.files[0]
    if (file) {
      if (
        _.includes(file.type, 'text') ||
        _.includes(file.type, 'application')
      ) {
        setSrcVideo('')
        setDisplayImage(false)
      }
      if (_.includes(file.type, 'image')) {
        setSrcVideo('')
        setDisplayImage(true)
      }
      if (_.includes(file.type, 'video')) {
        let blodUrl = URL.createObjectURL(file)
        setSrcVideo(blodUrl)
        setDisplayImage(false)
      }
    }
  }

  const handleSendFileMessage = () => {
    let formData = new FormData()
    formData.append('file', previewState.value?.imageFile!)
    formData.append('msg', previewState.value?.msg ?? '')
    formData.append('description', description ?? '')

    const option = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent
        let percent = Math.floor((loaded * 100) / total)

        if (percent) {
          setUploadPercent(percent)
        }
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel)
      ),
    }

    rocketChatClient
      .post(`v1/rooms.upload/${selectedRoomChat.rid}`, formData, option)
      .then(() => {
        setPreviewOpen(false)
        setUploadPercent(0)
        setAllowDisplayLoading(false)
      })
      .catch((thrown) => {
        if (axios.isCancel(thrown)) {
          console.log(thrown.message)
        }
        setUploadPercent(0)
        setAllowDisplayLoading(false)
      })
  }

  const cancelUpload = () => {
    if (cancelFileUpload.current) {
      cancelFileUpload.current()
    }
  }

  return (
    <div className={classes.root}>
      {previewState.loading && <CircularProgress />}
      {!previewState.loading && (
        <div className="makeStyle-input-attachfile">
          <input
            className={classes.input}
            id="icon-button-file"
            type="file"
            onChange={(event) => {
              setContentDisplay(event)
              previewFn(event.target.files)
            }}
            accept="audio/*,video/*,image/*,.xlsx,.xls,.doc,.docx,text/plain,application/pdf,"
          />
          <label htmlFor="icon-button-file">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              className="btn-link"
            >
              <img src="/images/AttachFileIcon.svg" />
            </IconButton>
          </label>
          <Dialog open={previewOpen} title="" className="popup-upload">
            <DialogContent className="p-3 text-center ">
              {displayImage && (
                <img
                  className="img-fluid mb-3"
                  style={{ width: 400 }}
                  src={previewState.value?.source}
                />
              )}
              {!_.isEmpty(srcVideo) && (
                <video controls autoPlay style={{ width: 500 }}>
                  <source src={srcVideo}></source>
                </video>
              )}
              {allowDisplayLoading && (
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <LinearProgressWithLabel value={uploadPercent} />
                </Box>
              )}
              <CssTextField
                size="small"
                variant="filled"
                label="File name"
                className="mb-3 input-field-upload"
                value={previewState.value?.msg}
                InputProps={{ readOnly: true }}
                fullWidth
              />
              <CssTextField
                fullWidth
                size="small"
                variant="filled"
                label="Description"
                className="mb-3 input-field-upload"
                value={description}
                onChange={(evt) => setDescription(evt.target.value)}
              />

              <div className="d-flex justify-content-between">
                <Button
                  variant="contained"
                  onClick={() => {
                    cancelUpload()
                    setPreviewOpen(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  disabled={allowDisplayLoading}
                  onClick={() => {
                    setAllowDisplayLoading(true)
                    handleSendFileMessage()
                  }}
                  color="primary"
                >
                  {allowDisplayLoading ? 'Sending' : 'Send'}
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      )}
    </div>
  )
}

export default RocketChatUpload
