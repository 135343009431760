const actionTypes = {
  GET_SUBS: "@@ROCKETCHAT_GET_SUBS",
  STORE_SUBS: "@@ROCKETCHAT_STORE_SUBS",
  START_STREAM_NOTIFY_USER: "@@ROCKETCHAT_START_STREAM_NOTIFY_USER",
  STOP_STREAM_NOTIFY_USER: "@@ROCKETCHAT_STOP_STREAM_NOTIFY_USER",
  START_STREAM_NOTIFY_LOGED: "@@ROCKETCHAT_START_STREAM_NOTIFY_LOGED",
  STOP_LOGED_STREAM_NOTIFY_LOGED: "@@ROCKETCHAT_LOGED_STREAM_NOTIFY_LOGED",
}

const getSubscriptions = (payload?: any) => ({ type: actionTypes.GET_SUBS, payload: payload });
const storeSubscriptions = (payload: any) => ({ type: actionTypes.STORE_SUBS, payload: payload });

const startStreamNotifyUser = (userId: string, event: string) => ({
  type: actionTypes.START_STREAM_NOTIFY_USER,
  event,
  userId
})

const startStreamNotifyLoged = () => ({
  type: actionTypes.START_STREAM_NOTIFY_LOGED
})

const stopStreamNotifyLoged = () => ({
  type: actionTypes.STOP_STREAM_NOTIFY_USER
})

export default {
  getSubscriptions,
  storeSubscriptions,
  startStreamNotifyUser,
  startStreamNotifyLoged,
  stopStreamNotifyLoged,
  actionTypes
}