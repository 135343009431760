import * as ls from 'local-storage';
import { all, takeLatest } from "redux-saga/effects";
import meActions from "../actions/meActions";


function* ME_SET_ROCKETCHAT_LOGIN_INFO({ rocketChatLoginInfo }: { type: string, rocketChatLoginInfo: any }) {
  ls.set("rocketchat.userId", rocketChatLoginInfo.data.userId)
  ls.set("rocketchat.authToken", rocketChatLoginInfo.data.authToken)
}

export default function* socketWatcher() {
  yield all([
    takeLatest(meActions.actionTypes.ME_SET_ROCKETCHAT_LOGIN_INFO, ME_SET_ROCKETCHAT_LOGIN_INFO),
  ]);
}
