import { Card, CircularProgress } from '@material-ui/core'
import connectionServices from '@services/connectionServices'
import {
  ConnectionModel,
  GetAllConnectionRequest,
} from '@services/connectionServices/dto'
import rocketChatActions from '@stores/actions/rocketChatActions'
import _ from 'lodash'
import React, { FC, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { useAsync, useList } from 'react-use'
import ChatListItem from '../ChatListItem'
import SearchControl from '../SearchControl'

const DEFAULT_PAGE_SIZE = 20
const ChatContact: FC = () => {
  const dispatch = useDispatch()
  const {
    rocketChatState: { userPresenceList },
  } = useSelector((state: any) => state)
  const [request, setRequest] = useState<GetAllConnectionRequest>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  })
  const [list, { set, reset }] = useList<ConnectionModel>([])

  const { value, loading } = useAsync(() => {
    return connectionServices.getAll({ ...request }).then((rs) => {
      const newList = _.concat(list, rs.data!)
      set(newList)
      return rs
    })
  }, [request])
  
  const handleOnSearch = (textSearch: string) => {
    reset()
    setRequest({ page: 1, pageSize: DEFAULT_PAGE_SIZE, by_name: textSearch })
  }

  const handleContactClicked = (rocketchat_username: string) => {
    dispatch(rocketChatActions.createDirectMessage(rocketchat_username))
  }

  const onlineShow = (item) => {
    const renderIcon = _.some(
      userPresenceList,
      (presence) =>
        presence.status === 'online' &&
        presence.username === item.rocketchat_username
    )
    return renderIcon
  }

  return (
    <React.Fragment>
      <div
        className="selection-chat chat-list d-flex flex-column justify-content-center align-items-start"
        style={{
          minWidth: 360,
          maxWidth: 360,
          height: '100vh',
          paddingTop: 24,
          paddingLeft: 32,
        }}
      >
        <h2 className="title-chat">Contacts</h2>
        <SearchControl
          onSearch={handleOnSearch}
          placeHolder="Search name friend"
        />
        <div
          className="d-flex flex-column flex-fill w-100 column-chat"
          id="chat-contact-container"
          style={{ backgroundColor: 'transparent' }}
        >
          {_.isEmpty(list) ? (
            <div
              className="icon-empty"
              style={{ marginTop: 20, marginLeft: 'auto', marginRight: 'auto' }}
            >
              <img src="/images/empty_contact.svg" alt="icon empty" />
              <p style={{ color: '#9d9d9d' }}>Empty contacts</p>
            </div>
          ) : ( 
            <InfiniteScroll
              dataLength={list.length}
              loader={<CircularProgress size={20} />}
              next={() => setRequest({ ...request, page: value!.page + 1 })}
              hasMore={_.some(value?.data)}
              scrollableTarget="chat-contact-container"
            >
              {_.map(list, (item, idx) => (
                <div className="box-item-chat contact">
                  <ChatListItem
                    urlProfile={true}
                    messageChat={true}
                    flat="flat"
                    onClick={() =>
                      handleContactClicked(item.rocketchat_username)
                    }
                    key={idx}
                    online={onlineShow(item)}
                    item={{
                      name: item.user_name,
                      avatar: item.avatar,
                      profileId: item.profile_id,
                      target: item.user_group_id,
                      lastMessage: [
                        _.get(item, 'school[0].name'),
                        _.get(item, 'school[0].state_name'),
                        _.get(item, 'school[0].county'),
                      ]
                        .filter(Boolean)
                        .join(', '),
                    }}
                  />
                </div>
              ))}
            </InfiniteScroll>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default ChatContact
