import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core'
import rocketChatServices from '@services/rocketChatServices'
import { FC } from 'react'
import { useAsyncFn } from 'react-use'

interface DeleteMessageDialogProps {
  message?: any
  affterDeleted?: (message: any) => void
  onClose?: () => void
}

const DeleteMessageDialog: FC<DeleteMessageDialogProps> = ({
  message,
  affterDeleted,
  onClose,
}) => {
  const [{ loading }, deleteMessage] = useAsyncFn(
    (id: string) => {
      return rocketChatServices
        .deleteMessage(id)
        .then(() => affterDeleted?.(message))
    },
    [affterDeleted]
  )

  return (
      <Dialog
        className="dialog-delete st-popup-delete"
        aria-labelledby="simple-dialog-title"
        open={Boolean(message)}
      >
        <div className="popup-delete">
          <div className="d-flex align-items-center">
            <img src="/images/icon-why.svg" />
            <DialogTitle
              id="simple-dialog-title"
              style={{
                fontSize: 14,
                padding: 0,
                lineHeight: 'unset',
                paddingLeft: 8,
              }}
            >
              Are you sure?
            </DialogTitle>
          </div>

          <DialogContent
            style={{
              padding: '20px 35px',
            }}
          >
            <h4
              style={{
                color: '#ffffff',
                fontSize: 14,
              }}
            >
              You will not be able to recover this message!
            </h4>
          </DialogContent>
          <DialogActions>
            <Button
              className='btn-hover'
              onClick={onClose}
              variant="outlined"
              style={{
                border: '1px solid #a9a9a9',
                color: '#a9a9a9',
                fontSize: 12,
              }}
            >
              Cancel
            </Button>
            <Button
              className='btn-hover'
              variant="contained"
              disabled={loading}
              onClick={() => deleteMessage(message.id)}
              style={{
                fontSize: 12,
                background: "#D32F2F",
                color: "#ffffff"
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </div>
      </Dialog>
  )
}

export default DeleteMessageDialog
