import { combineEpics } from "redux-observable";
import rocketChatEpics from "./rocketChatEpics";

export default combineEpics(
  rocketChatEpics.startStreamNotifyUser,
  rocketChatEpics.initConnection,
  rocketChatEpics.setCurrentLoginInfo,
  rocketChatEpics.startSteamNotifyLogged,
  rocketChatEpics.loginForRealtimeApi,
  rocketChatEpics.subscribeToRoom,
  rocketChatEpics.getSubscriptions,
  rocketChatEpics.createDirectChat,
  rocketChatEpics.loadHistoryForFirstTime,
  rocketChatEpics.loadHistory,
  rocketChatEpics.sendMessage,
  rocketChatEpics.scrollDown,
  rocketChatEpics.readMessages,
)