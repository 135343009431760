import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { persistor, store } from './stores';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import ProtectedRoute from '@components/ProtectedRoute';
import Login from '@pages/Login';
import Chat from '@pages/Chat';


const Application = (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={Login} />
          <ProtectedRoute path="/" render={() => (
            <App>
              <Chat />
            </App>
          )} />
        </Switch>
      </BrowserRouter>
    </PersistGate>
  </Provider>
)

ReactDOM.render(Application, document.getElementById('root'));
