import cookies from 'js-cookie'
import _ from 'lodash'

const isLoged = () => Boolean(getAuthToken())

const getAuthToken = () => _.trim(cookies.get('.rosterspot.authorization.v2'))

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  isLoged,
  getAuthToken,
}
