import { FC } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const UserChatSkeleton: FC = () => {
  return (
    <SkeletonTheme baseColor="#D3D3D3" highlightColor="#FFFFFF">
      <p className="d-flex">
        <Skeleton count={1} duration={2} circle={true} borderRadius={1} width={50} height={50} />
        <div className="pt-2 d-flex flex-column align-items-start" style={{ marginLeft: 10 }}>
          <Skeleton count={1} duration={2} width={80} height={15} />
          <Skeleton count={1} duration={2} width={230} height={15} />
        </div>
      </p>
    </SkeletonTheme>
  )
}

export default UserChatSkeleton