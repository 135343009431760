import rocketChatServices from "@services/rocketChatServices";
import { RoomResult } from "@services/rocketChatServices/roomDtos";
import rocketChatActions from "@stores/actions/rocketChatActions";
import RocketUtils from "@utils/rocketUtil";
import jscookie from 'js-cookie';
import _ from "lodash";
import moment from "moment";
import { all, put, select, takeLatest, delay } from "redux-saga/effects";
import { v4 } from "uuid";

function* getRoomsEffect({ subscriptions }: { type: string, subscriptions: any }) {
  const roomResult: RoomResult = yield rocketChatServices.getRooms()
  const resRooms = _.get(roomResult, ['message', 'result'], [])
  const parsedRooms = resRooms.map((room) => RocketUtils.normalizeRoom(room))
  const rooms = _.keyBy(parsedRooms, '_id')
  const usename_selected = jscookie.get('rosterspot.usename_selected')
  try {
    yield put(rocketChatActions.setChatListLoading(true))

    const normalizedSubroom = _.map(subscriptions, item => {
      item.room = _.get(rooms, item.rid)
      const parsedSubroom = RocketUtils.normalizeSubroom(item)
      return parsedSubroom
    })
    yield put(rocketChatActions.prepareSubscriptions(_.keyBy(normalizedSubroom, 'rid')))
    if (usename_selected) {
      yield delay(200)
      yield put(rocketChatActions.createDirectMessage(usename_selected))
      const rsusers = yield rocketChatServices.fetchRosterUsers([usename_selected])
      yield put(rocketChatActions.setRsUsers(rsusers))
    }

  } catch (error) {

  } finally {
    yield put(rocketChatActions.setChatListLoading(false))
  }
}

function* prepareDirectMessageEffect({ rid }: { type: string, rid: string }) {
  let { subscription } = yield rocketChatServices.getOneSubscription(rid)

  if (subscription) {
    subscription.room = yield rocketChatServices.getRoomInfo(rid)
    const normalizedSubroom = RocketUtils.normalizeSubroom(subscription)

    yield put(rocketChatActions.setOneSubscription(rid, normalizedSubroom))
    yield put(rocketChatActions.clearRoomChatHistories())
    yield put(rocketChatActions.selectRoomChat(normalizedSubroom))
    yield put(rocketChatActions.unsubscribeToRoom())
    yield put(rocketChatActions.subscribeToRoom(rid))
  }
}

function* setRoomChatLastMessageEffect({ rid, message }: { type: string, rid: string, message: any }) {
  const { subscriptions } = yield select(state => state.rocketChatState)
  const normalizedMessage = RocketUtils.normalizeMessage(message)
  _.set(subscriptions, [rid, "room", "lastMessage"], normalizedMessage)
  _.set(subscriptions, [rid, "room", "lm"], normalizedMessage.ts)
}

function* increaseRoomUnreadEffect({ rid, payload }: { type: string, rid: string, payload: any }) {
  const { message: { msg } } = payload
  const { subscriptions } = yield select(state => state.rocketChatState)
  const newSubscription = yield rocketChatServices.getOneSubscription(rid)
  const subscription = _.get(subscriptions, rid)

  _.set(subscription, ["room", "lastMessage", 'msg'], msg)
  _.set(subscription, ["room", "lm"], moment().unix())
  _.set(subscription, "unread", newSubscription.subscription.unread)
  _.set(subscription, "key", v4())
  const normalizedSubroom = RocketUtils.normalizeSubroom(subscription)
  yield put(rocketChatActions.updateOneSubscription(normalizedSubroom))
}

function* loadHistoryForFistTime({ loadHistoryResult, selectedRoomChat }: { type: string, loadHistoryResult: any, selectedRoomChat: any }) {
  const histories = _.get(loadHistoryResult, "result.messages", [])
  yield put(rocketChatActions.setRoomChatHistory(selectedRoomChat.rid, histories))
  yield put(rocketChatActions.scrollDown())
}

// eslint-disable-next-line require-yield
function* setCurrentLoginInfo({ rcCurrentLoginInfo }: { rcCurrentLoginInfo: any, type: string }) {
  jscookie.set('x-user-id', rcCurrentLoginInfo.data.userId)
  jscookie.set('x-auth-token', rcCurrentLoginInfo.data.authToken)
}

// eslint-disable-next-line require-yield
function* selectedRoomChatEffect({ selectedRoomChat }: { type: string, selectedRoomChat: any }) {
  jscookie.set('rosterspot.usename_selected', selectedRoomChat.name, {
    domain: process.env.REACT_APP_DOMAIN,
    path: '/'
  })
}

function* fetchRosterUsers(action: any) {
  let usernames: string[] = []

  if (_.has(action, 'subscription'))
    usernames.push(action.subscription.name)

  if (action.type === rocketChatActions.actionTypes.SET_USER_PRESENCE_LIST && _.has(action, 'userPresenceList'))
    usernames = _.concat(usernames, _.map(action.userPresenceList, 'username'))

  if (action.type === rocketChatActions.actionTypes.PREPARE_NEW_SUBSCRIPTION) {
    const { rid, username } = action
    let { subscription } = yield rocketChatServices.getOneSubscription(rid)
    if (subscription) {
      usernames.push(username)
      subscription.room = yield rocketChatServices.getRoomInfo(rid)

      yield put(rocketChatActions.setOneSubscription(rid, subscription))
    }
  }

  if (action.type === rocketChatActions.actionTypes.CREATE_DIRECT_MESSAGE)
    usernames.push(action.username)

  if (action.type === rocketChatActions.actionTypes.SET_CURRENT_LOGIN_INFO) {
    const { data: { me: { username } } } = action.rcCurrentLoginInfo
    usernames.push(username)
  }

  const rsusers: any = yield rocketChatServices.fetchRosterUsers(usernames.filter(Boolean))
  yield put(rocketChatActions.setRsUsers(rsusers))
}

export default function* rocketChatSagas() {
  yield all([
    takeLatest(rocketChatActions.actionTypes.SET_SUBSCRIPTIONS, getRoomsEffect),
    takeLatest(rocketChatActions.actionTypes.PREPARE_DIRECT_MESSAGE, prepareDirectMessageEffect),
    takeLatest(rocketChatActions.actionTypes.SET_ROOM_CHAT_LAST_MESSAGE, setRoomChatLastMessageEffect),
    takeLatest(rocketChatActions.actionTypes.INCREASE_ROOM_UNREAD, increaseRoomUnreadEffect),
    takeLatest(rocketChatActions.actionTypes.LOAD_HISTORY_FOR_FIRST_TIME, loadHistoryForFistTime),
    takeLatest(rocketChatActions.actionTypes.SET_CURRENT_LOGIN_INFO, setCurrentLoginInfo),
    takeLatest(rocketChatActions.actionTypes.SELECT_ROOM_CHAT, selectedRoomChatEffect),
    takeLatest(rocketChatActions.actionTypes.SET_SUBSCRIPTIONS, fetchRosterUsers),
    takeLatest(rocketChatActions.actionTypes.SET_USER_PRESENCE_LIST, fetchRosterUsers),
    takeLatest(rocketChatActions.actionTypes.PREPARE_NEW_SUBSCRIPTION, fetchRosterUsers),
    takeLatest(rocketChatActions.actionTypes.CREATE_DIRECT_MESSAGE, fetchRosterUsers),
    takeLatest(rocketChatActions.actionTypes.SET_CURRENT_LOGIN_INFO, fetchRosterUsers),
  ]);
}
