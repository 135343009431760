import meActions from "../actions/meActions"

export interface MeState {
  profile?: any
  rocketChatLoginInfo?: any
}

const initializeState: MeState = {
  rocketChatLoginInfo: {
    data: {
      me: {

      }
    }
  },
  profile: {

  }
}

const reducer = (state = initializeState, action: any) => {
  switch (action.type) {
    case meActions.actionTypes.ME_SET_PROFILE:
      return {
        ...state,
        profile: action.profile
      }
    case meActions.actionTypes.ME_SET_ROCKETCHAT_LOGIN_INFO:
      return {
        ...state,
        rocketChatLoginInfo: action.rocketChatLoginInfo
      }
    default:
      return state
  }
}

export default reducer