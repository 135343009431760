import ErrorSnackbar from '@components/Errors/ErrorSnackbar'
import { CircularProgress } from '@material-ui/core'
import rocketChatServices from '@services/rocketChatServices'
import rocketChatActions from '@stores/actions/rocketChatActions'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAsync } from 'react-use'
import 'symbol-observable'
import './App.css'
import rsApiRequest from './services/rsApiRequest'
import meActions from './stores/actions/meActions'
import './styles/app.style.scss'

const App: FC = (props) => {
  const dispatch = useDispatch()
  const { retry } = useSelector((state: any) => state.rocketChatState)
  const { loading } = useAsync(async () => {
    const profile: any = await rsApiRequest.get('profile')
    const { rocketchat_username: user, rocketchat_password: password } = profile
    const rocketchatLogin = await rocketChatServices.login(user, password)
    dispatch(rocketChatActions.setCurrentLoginInfo(rocketchatLogin))
    dispatch(meActions.setLoginInfo(profile))
    dispatch(
      rocketChatActions.loginForRealtimeApi({ username: user, password })
    )
  }, [])

  useEffect(() => {
    dispatch(rocketChatActions.initConnection())
    return () => {
      dispatch(rocketChatActions.stopStreamNotifyLoged())
    }
  }, [retry])

  // if (loading) return <CircularProgress className='d-flex justify-content-center align-items-center' style={{ color: '#000000' }} />

  return (
    <div
      className="App  d-flex flex-column flex-fill"
      style={{ backgroundColor: '#1f1f1f' }}

    >
      {loading && (
        <div className="d-flex justify-content-center h-100 align-items-center">
          <CircularProgress style={{ color: '#fff' }} />
        </div>
      )}
      {!loading && (
        <>
          <div className="d-flex flex-column flex-fill">{props.children}</div>
          <ErrorSnackbar />
        </>
      )}
    </div>
  )
}

export default App
