const actionTypes = {
  ADD_ERROR: "@@ROCKETCHAT_ADD_ERROR"
}

const addError = (payload: any) => ({
  type: actionTypes.ADD_ERROR,
  payload
})

export default {
  actionTypes,
  addError
}