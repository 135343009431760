import sessionServices from '@services/sessionServices'
import { Redirect } from 'react-router'

export default () => {

  if (sessionServices.isLoged())
    return <Redirect to={{ pathname: '/chat' }} />


  return (
    <div>
      Login
    </div>
  )
}