const actionTypes = {
  SET_CHAT_ACTIVE_TAB: '@@LAYOUT_SET_CHAT_ACTIVE_TAB'
}

const setChatActiveTab = (activeTabId: number) => ({
  type: actionTypes.SET_CHAT_ACTIVE_TAB,
  activeTabId
})

export default {
  actionTypes,
  setChatActiveTab
}