import _ from 'lodash'
import { applyMiddleware, compose, createStore } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import logger from 'redux-logger'
import { RealTimeAPI } from 'rocket.chat.realtime.api.rxjs'
import rcErrorActions from './actions/rcErrorActions'
import epics from './epics/combinedEpics'
import rootReducer from './reducers'
import rootSaga from './sagas'

let realtimeAPI = new RealTimeAPI(process.env.REACT_APP_ROCKETCHAT_WS!)
const reduxDevTools = process.env.NODE_ENV === 'development'
const persistConfig = {
  key: 'RosterSpot.Storage',
  storage, // define which storage to use
  whitelist: ['meState', 'rcLoginState'],
}

realtimeAPI.onError((err) => {
  store.dispatch(rcErrorActions.addError({ reason: 'Error', err }))
})

realtimeAPI.onMessage((msg: any) => {
  if (typeof msg.type === 'string' && msg.type === 'error')
    store.dispatch(
      rcErrorActions.addError({ reason: 'Error Connecting to Server', msg })
    )
})

realtimeAPI.onCompletion(() => {
  store.dispatch(
    rcErrorActions.addError({ reason: 'Disconnected from server' })
  )

  realtimeAPI.webSocket.retry(1).subscribe(() => {
    window.location.reload()
  })
})

realtimeAPI.keepAlive() // Ping Server

const persistedReducer = persistReducer(persistConfig, rootReducer) // create a persisted reducer
const sagaMiddleware = createSagaMiddleware()
const epicMiddleware = createEpicMiddleware({
  dependencies: {
    realtimeAPI,
  },
})

const middlewares = applyMiddleware(sagaMiddleware, epicMiddleware)
const composeEnhancers = _.get(
  window,
  '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__',
  compose
)
const store = !reduxDevTools
  ? createStore(persistedReducer, middlewares)
  : createStore(persistedReducer, composeEnhancers(middlewares))

epicMiddleware.run(epics)
sagaMiddleware.run(rootSaga)

const persistor = persistStore(store)

export { store, persistor }
