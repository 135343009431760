import rcErrorActions from "@stores/actions/rcErrorActions";

export default function (state = [], action: any) {
  switch (action.type) {
    case rcErrorActions.actionTypes.ADD_ERROR:
      return [...state, action.payload]

    default:
      return state
  }
}