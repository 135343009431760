const actionTypes = {
  ME_SET_PROFILE: '@@ME_SET_PROFILE',
  ME_SET_ROCKETCHAT_LOGIN_INFO: '@@ME_SET_ROCKETCHAT_LOGIN_INFO'
}

const setLoginInfo = (profile: any) => ({
  type: actionTypes.ME_SET_PROFILE,
  profile
})

const setRocketChatLoginInfo = (rocketChatLoginInfo: any) => {
  return {
    type: actionTypes.ME_SET_ROCKETCHAT_LOGIN_INFO,
    rocketChatLoginInfo
  }
}

export default {
  setLoginInfo,
  setRocketChatLoginInfo,
  actionTypes
}